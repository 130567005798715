import React from 'react'
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

const Main = (props) => {

    return (
        <main id="main" className="relative" >
            <div className="pb-8">
                <Outlet />
            </div>
            <Footer />
        </main>
    )
}

export default Main;