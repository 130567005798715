import React, { useContext, useEffect, useState } from 'react'
import { Box, Paper, TextField, Button } from '@mui/material'
import UserContext from '../../state/UserContext'
import APIInvoke from '../../utils/APIInvoke'
import { toast } from 'react-hot-toast';
import { useParams, useNavigate, Link } from "react-router-dom";

const Login = (props) => {

    const navigate = useNavigate()
    const { user, setUser } = useContext(UserContext)
    const [state, setState] = useState({
        username: "",
        password: ""
    })

    useEffect(() => {
        autoLogin()
    }, [])

    const autoLogin = async () => {
        const localStorage = window.localStorage;
        const token = localStorage.getItem("token")
        if (!token) return

        const rawResponse = await APIInvoke.invokeGET(`/users/me`)


        if (rawResponse.status === 401) {
            localStorage.removeItem("token")
            return
        }

        const response = await rawResponse.json()
        if (response.ok) {
            setUser({
                ...user,
                ...response.body.user
            })
            toast.success(response.message)
            window.location = "/"
        }
    }

    const login = async (e) => {
        e.preventDefault()
        const request = {
            user: state.username,
            password: state.password
        }
        const response = await (await APIInvoke.invokePOST(`/users/login`, request)).json()
        if (response.ok) {
            const localStorage = window.localStorage;
            localStorage.setItem("token", response.body.token);
            setUser({
                ...response.body.user,
                roles: response.body.roles,
                permissions: response.body.permissions
            })
            toast.success(response.message)
            window.location = "/"
        } else {
            toast.error(response.message)
        }
    }

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="pt-20">
            <div className="max-w-96 m-auto">
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }} className="p-4">
                        <form onSubmit={login}>
                            <div className="grid gap-4">

                                <TextField
                                    required
                                    size="medium"
                                    label="Username"
                                    name="username"
                                    onChange={onChange}
                                    value={state.username}
                                />
                                <TextField
                                    required
                                    size="medium"
                                    label="Password"
                                    name="password"
                                    onChange={onChange}
                                    value={state.password}
                                    type="password"
                                />
                                <Button variant="contained" type="submit" className="text-dark-500" onClick={login}>LOGIN</Button>
                            </div>
                        </form>
                    </Paper>
                </Box>
                <div>
                    <Link className="block text-center" to={`/restore`}>Did you forget your password?</Link>
                </div>
            </div>
        </div>
    )
}

export default Login

