import { DateTime } from "luxon";


export const fromISOToUTC = (dateISO) => {
    if (dateISO == null) return null;
    const localDateTime = DateTime.fromISO(dateISO, { zone: 'utc' }).toLocal();
    return localDateTime;
}

export const fromISOToUTCFormat = (dateISO, format) => {
    if (dateISO == null) return null;
    const localDateTime = DateTime.fromISO(dateISO, { zone: 'utc' }).toLocal();
    return localDateTime.toFormat(format);
}


export const fromISOToLocal = (dateISO) => {
    if (dateISO == null) return null;
    const date = DateTime.fromISO(dateISO, { zone: 'utc' });
    return date.toLocal().toFormat("yyyy-MM-dd'T'HH:mm:ss");
}




