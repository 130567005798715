import {
    EDIT_COLLECTIONS_GENERALES,
    EDIT_COLLECTIONS_METADATA_MAPPER,
    EDIT_COLLECTIONS_RESET,
    EDIT_COLLECTIONS_RULES_CONFIG
} from "../redux-const";

const initialState = {
    loading: false,
    tab: 0,
    new: true,
    useSchema: false,
    name: "",
    schema: "",
    skipUnchangedDocuments: false,

    useSchema: false,
    rejectInvalid: false,
    schemaType: "JSON",
    schema: "",
    

    rulesConfig: {
        rulesExample: "",
        rulesPreviewExample: "",
        rules: [],
        notifyTo: ['oscarblancarte3@gmail.com', 'oscar_jb1@hotmail.com', 'oscar@oscarblancarte.com']
    },


    metadataMapper: {
        enabled: false,
        mapperMode: "merge",
        documentExample: "",
        expression: ""
    }
}


export const reducer = (state = initialState, action) => {
    const payload = action.payload;

    switch (action.type) {
        case EDIT_COLLECTIONS_GENERALES:
            return {
                ...state,
                ...payload
            }
        case EDIT_COLLECTIONS_RESET:
            return initialState
        case EDIT_COLLECTIONS_RULES_CONFIG:
            return {
                ...state,
                rulesConfig: {
                    ...payload
                }
            }
        case EDIT_COLLECTIONS_METADATA_MAPPER:
            return {
                ...state,
                metadataMapper: {
                    ...payload
                }
            }
        default:
            return state
    }
};

export default reducer;
