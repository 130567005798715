import {
    EDIT_USER_RESET,
    EDIT_USER_GENERALES,
    EDIT_USER_ROLES,
    EDIT_USER_PERMISSIONS
} from "../redux-const";

const initialState = {
    loading: false,
    tab: 0,
    new: true,
    username: "",
    name: "",
    email: "",
    password: "",
    password2: "",
    active: true,
    permissions: [],
    roles: [],
    allPermissions: [],
    allRoles: []
}


export const reducer = (state = initialState, action) => {
    const payload = action.payload;
    const roles = state.roles
    const permission = state.permissions

    switch (action.type) {
        case EDIT_USER_GENERALES:
            return {
                ...state,
                ...payload
            }
        case EDIT_USER_RESET:
            return initialState
        case EDIT_USER_ROLES: 
            roles[payload.index].selected = !roles[payload.index].selected
            return {
                ...state,
                roles: [...state.roles]
            }
        case EDIT_USER_PERMISSIONS: 
            permission[payload.index].selected = !permission[payload.index].selected
            return {
                ...state,
                roles: [...state.roles]
            }
        default:
            return state
    }
};

export default reducer;
