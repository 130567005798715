import store from '../../redux/store'
import {
    EDIT_TOKEN_GENERALES,
    EDIT_TOKEN_PERMISSIONS,
    EDIT_TOKEN_RESET,
    EDIT_TOKEN_ROLES
} from '../../redux/redux-const'
import APIInvoke from '../../utils/APIInvoke';
import toast from 'react-hot-toast';
import { DateTime } from 'luxon';


const getState = () => {
    return store.getState().pages.editToken
}

const merge = (type, param) => {
    store.dispatch({
        type,
        payload: {
            ...getState(),
            ...param
        }
    })
}

export const reset = () => {
    store.dispatch({ type: EDIT_TOKEN_RESET })
}

export const init = async () => {
    let responseUser = null
    const responsePermissions = (await (await APIInvoke.invokeGET(`/security/permissions`)).json()).body
        .map(item => {
            return {
                ...item,
                selected: false
            }
        })
    const responseRoles = (await (await APIInvoke.invokeGET(`/security/roles`)).json()).body
        .map(item => {
            return {
                ...item,
                selected: false
            }
        })

    merge(EDIT_TOKEN_GENERALES, {
        ...responseUser,
        roles: responseRoles,
        permissions: responsePermissions
    })
}

export const updateGenerales = (action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value


    if (type === "checkbox") {
        value = action.target.checked
    }
    merge(EDIT_TOKEN_GENERALES, { [name]: value })
}

export const checkRole = (e, index) => {
    merge(EDIT_TOKEN_ROLES, { index })
}

export const checkPermissions = (e, index) => {
    merge(EDIT_TOKEN_PERMISSIONS, { index })
}

export const changeExpirationRange = (e) => {
    const name = e.target.name
    const value = parseInt(e.target.value)

    let date = null
    if (value === 0) {
        date = DateTime.now()
    } else if (value === -1) {
        date = DateTime.now()
    } else {
        date = DateTime.now().plus({ days: value })
    }

    merge(EDIT_TOKEN_GENERALES, { [name]: value, expiration: date })
}

export const save = async (e, project) => {
    e.preventDefault()
    const state = getState()
    
    

    const request = {
        name: state.name,
        roles: state.roles.filter(r => r.selected).map(r => r.name),
        permissions: state.permissions.filter(p => p.selected).map(p => p.name)
    }


    if( (request.roles.length + request.permissions.length) === 0 ) {
        toast.error("Select at least one role or privilege")
        return
    }

    const response = await (await APIInvoke.invokePOST(`/projects/${project}/tokens`, request)).json()
    if (response.ok) {
        merge(EDIT_TOKEN_GENERALES, {
            new: false,
            id: response.body.id,
            token: response.body.token
        })
        toast.success(response.message)
    } else {
        toast.error(response.message)
    }
}