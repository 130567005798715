import React, { useContext } from 'react'
import UserContext from '../state/UserContext'

const ScreenDebug = (props) => {

    const { user } = useContext(UserContext)
    const permissions = user.permissions || []
    const roles = user.roles || []

    if (process.env.NODE_ENV === "production") return null
    return (
        <div className="" style={{ color: "red" }}>
            <span className="fixed z-50 " style={{ color: "red" }}>
                <span className="z-50 block sm:hidden">XS</span>
                <span className="z-50 hidden sm:block md:hidden">SM</span>
                <span className="z-50 hidden md:block lg:hidden">MD</span>
                <span className="z-50 hidden lg:block xl:hidden">LG</span>
                <span className="z-50 hidden xl:block 2x1:hidden">XL</span>
                <span className="z-50 hidden 2x1:block">2xl</span>
            </span>
            <div className="fixed z-50 bottom-1 left-1" style={{ color: "red" }}>
                <div className="text-sm">Roles: {roles}</div>
                <div className="text-sm">Permissions: {permissions.join(",")}</div>
            </div>
        </div>

    )
}
export default ScreenDebug