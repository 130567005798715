import React, { useContext } from 'react'
import { Breadcrumbs, Typography } from '@mui/material';
import AppContext from '../../state/AppContext';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';


const Project = (props) => {

    const { appContext } = useContext(AppContext)
    const project = appContext.project

    return (
        <div className="content">
            <div className="grid gap-8">
                <div className="layout grid gap-4">
                    <Typography variant="h4" component="h4">
                        {project}
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                        <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                        <Typography ><span className="text-gray-600">Projects</span></Typography>
                        <Typography ><span className="text-gray-600">{project}</span></Typography>
                    </Breadcrumbs>
                </div>
                <div >
                </div>
            </div>
        </div>
    )
}

export default Project