const config = {
    api: {
        baseUrl: "http://localhost:8080" //"https://goback-api-69984414743.us-central1.run.app", // http://localhost:8080
    },
    contacts: {
        emailContacto: "info@goback.mx",
        emailSoporte: "info@goback.mx"
    }
}

module.exports = config