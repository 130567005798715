import {
    EDIT_PROJECT_RESET,
    EDIT_PROJECT_GENERALES
} from "../redux-const";

const initialState = {
    loading: false,
    tab: 0,
    new: true,
    name: "",
    showDeleteDialog: {
        open: false
    },
    tokens: []
}


export const reducer = (state = initialState, action) => {
    const payload = action.payload;

    switch (action.type) {
        case EDIT_PROJECT_GENERALES:
            return {
                ...state,
                ...payload
            }
        case EDIT_PROJECT_RESET:
            return initialState
        default:
            return state
    }
};

export default reducer;
