import { DevTool } from '@hookform/devtools';
import { Box, Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react'
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns = [
    {
        field: 'name',
        editable: true,
        headerClassName: 'bg-gray-200',
        headerName: 'NAME',
        flex: 50,
    }, {
        field: 'value',
        editable: true,
        headerClassName: 'bg-gray-200',
        headerName: 'VALUE',
        flex: 50,
    }
]

const EditAppMonitorGenerals = ({ form }) => {

    const { register, formState, control } = form;
    const { errors } = formState

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'headers',
    });

    return (
        <div className="grid gap-4">
            <div className="grid grid-cols-3 gap-4">
                <FormControl size="medium" className="" error={!!errors.type}>
                    <InputLabel id="type-label" >Application Type</InputLabel>
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        error={!!errors.type}
                        render={({ field: { onChange, value } }) => (
                            <Select
                                error={!!errors.type}
                                name="type"
                                id="type"
                                labelId="type-label"
                                value={value}
                                onChange={onChange}
                                {...register("type", { required: "Type field is required" })}
                            >
                                <MenuItem value="REST_APP_MONITOR">REST Service</MenuItem>
                                <MenuItem value="SOAP_APP_MONITOR">SOAP Service</MenuItem>
                                <MenuItem value="HTTP_APP_MONITOR">HTTP Endpoint</MenuItem>
                                <MenuItem value="TCP_APP_MONITOR">TCP/IP Connection</MenuItem>
                                <MenuItem value="SOCKET_APP_MONITOR">Socket Connection</MenuItem>
                                <MenuItem value="SSH_APP_MONITOR">SSH Connection</MenuItem>
                            </Select>
                        )}
                    />
                    <FormHelperText className="!text-red-500">{errors.type?.message}</FormHelperText>
                </FormControl>
            </div>

            <div className="grid grid-cols-3 gap-4">
                <TextField label="name" type="text" {...register("name", { required: "Name field is required" })} error={!!errors.name} helperText={errors.name?.message} />
            </div>

            <div className="grid grid-cols-3 gap-4">
                <TextField multiline rows={3} label="description" className="col-span-2" type="text" {...register("description")} error={!!errors.description} helperText={errors.description?.message} />
            </div>


            <div className="py-8">
                <Divider className="" textAlign="left"></Divider>
            </div>

            <div className="flex gap-4 ">
                <FormControl size="medium" className="w-32" error={!!errors.method}>
                    <InputLabel id="method-label" >Method</InputLabel>
                    <Controller
                        name="method"
                        control={control}
                        rules={{ required: true }}
                        error={!!errors.method}
                        render={({ field: { onChange, value } }) => (
                            <Select
                                error={!!errors.method}
                                name="method"
                                id="method"
                                labelId="method-label"
                                value={value}
                                onChange={onChange}
                                {...register("method", { required: "Method field is required" })}
                            >
                                <MenuItem value="GET" >GET</MenuItem>
                                <MenuItem value="POST" >POST</MenuItem>
                                <MenuItem value="PUT" >PUT</MenuItem>
                                <MenuItem value="HEAD" >HEAD</MenuItem>
                                <MenuItem value="DELETE" >DELETE</MenuItem>
                                <MenuItem value="PATCH" >PATCH</MenuItem>
                                <MenuItem value="OPTIONS" >OPTIONS</MenuItem>
                                <MenuItem value="CONNECT" >CONNECT</MenuItem>
                                <MenuItem value="TRACE" >TRACE</MenuItem>
                            </Select>
                        )}
                    />
                    <FormHelperText className="!text-red-500">{errors.method?.message}</FormHelperText>
                </FormControl>

                <TextField className="flex-1" label="Test URL or IP *" type="text" {...register("testURL", { required: "Test URL field is required" })} error={!!errors.testURL} helperText={errors.testURL?.message} />

                <TextField className="w-36" label="Response Code *" type="number" {...register("excepctedResponseCode", { required: "Field required" })} error={!!errors.excepctedResponseCode} helperText={errors.excepctedResponseCode?.message} />
            </div>

            <div className="grid grid-cols-5 gap-4">
                <TextField label="Ping Interval (minutes) *" type="number" {...register("pingInterval", { required: "Ping Interval field is required" })} error={!!errors.pingInterval} helperText={errors.pingInterval?.message} />
                <TextField label="Max Failed Pings *" type="number" {...register("maxFailedPings", { required: "Max Failed Pings field is required" })} error={!!errors.maxFailedPings} helperText={errors.maxFailedPings?.message} />
            </div>
            
            <Box sx={{ height: 300 }}>
                <DataGrid
                    rows={fields}
                    columns={columns}
                    hideFooterPagination={true}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                        },
                    }}
                    pagination={false}
                    disableRowSelectionOnClick
                    slots={{
                        footer: () => (
                            <div className="border-t p-2 bg-gray-200">
                                <Button type="button" variant="contained" className="text-dark-500 !bg-gray-900" onClick={() => append({name: "", value: ""})}>ADD HEADER</Button>
                            </div>
                        )
                    }}
                />
            </Box>
            <TextField multiline rows={3} label="Request Body" type="text" {...register("body")} error={!!errors.body} helperText={errors.body?.message} />
        </div>
    )
}

export default EditAppMonitorGenerals;