import React, { useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015, foundation, github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useParams } from "react-router-dom";


const DocumentDiff = (props) => {

    let { documentId } = useParams();
    const [diff, setDiff] = useState([])
    const [state, setState] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        const init = async () => {
            const responseDiff = await (await APIInvoke.invokeGET("/documents/diff/65f64b966d90da729a759ee5/65f65ee36d90da729a759f02")).json()
            setDiff(responseDiff.body)

            const response = await (await APIInvoke.invokeGET("/documents?collection=polizas")).json()
            setState(response.body.items)
        }
        init()
    }, [])


    return (
        <div className="lg:my-5 lg:mx-40 min-h-screen ">
            {state.map((doc, index) => (
                <div className="grid grid-cols-2 gap-4 h-96" key={index}>
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={github}>
                        {JSON.stringify(doc.payload, null, 2)}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="diff" style={github}>
                        {diff.diffs}
                    </SyntaxHighlighter>
                </div>
            ))}
        </div>
    )
}
export default DocumentDiff