import React, { useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { useParams } from 'react-router-dom'
import { Box, Breadcrumbs, Paper, TextField, Typography } from '@mui/material'

// https://json-diff-kit.js.org/#l=%7B%0A++%22noPoliza%22%3A+%221234567890%22%2C%0A++%22total%22%3A+100000%2C%0A++%22iva%22%3A+10000%2C%0A++%22cliente%22%3A+%22Oscar+Blancarte%22%2C%0A++%22rfc%22%3A+%22BAIO8601201I5%22%0A%7D&r=%7B%0A++%22noPoliza%22%3A+%221234567890%22%2C%0A++%22total%22%3A+100000%2C%0A++%22iva%22%3A+2313%2C%0A++%22cliente%22%3A+%22Oscar+Blancarte%22%2C%0A++%22rfc%22%3A+%22BAIO8601201I5%22%0A%7D
import { Differ, Viewer } from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import AppContext from '../../state/AppContext';
import { setLoading } from '../../template/app-actions';
import toast from 'react-hot-toast';
import Header from '../../template/Header';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const differ = new Differ({
    detectCircular: true,    // default `true`
    maxDepth: Infinity,      // default `Infinity`
    showModifications: true, // default `true`
    arrayDiffMethod: 'normal',  // default `"normal"`, but `"lcs"` may be more useful
    sortResultLines: false,
    preserveKeyOrder: 'after'
});

const AuditLogDetail = (props) => {

    const { auditlogId } = useParams()
    const { appContext } = useContext(AppContext)
    const project = appContext.project

    const [state, setState] = useState(undefined)


    useEffect(() => {
        try {
            setLoading(true)
            init()
        } catch (error) {
            toast.error("Unexpected error")
        } finally {
            setLoading(false)
        }
    }, [auditlogId])

    const init = async () => {
        const response = await (await APIInvoke.invokeGET(`/projects/${project}/auditlogs/${auditlogId}`)).json()
        setState(response.body)
    }


    

    const InnerHeader = () => {
        return (
            <Header title={<span>AuditLog Event<span className="text-xs text-gray-700">/ {auditlogId} </span></span>} >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">AuditLogs</span></Typography>
                    <Typography ><span className="text-gray-600">Detail</span></Typography>
                </Breadcrumbs>
            </Header>
        )
    }

    if (state == undefined) return <InnerHeader />

    return (

        <div className="">
            <InnerHeader />
            <div className="content grid gap-8">
                <div className="flex flex-row gap-4">
                    <p className="flex-1">{state.description}</p>
                    {/* <p className="text-green-500">Session <Link to="#" className="text-sm">{state.sessionId}</Link></p> */}
                </div>
                <div className="grid grid-cols-2 gap-8">
                    <Box >
                        <Paper className="p-2">
                            <div className="grid gap-4 p-4 rounded-md">
                                <TextField
                                    size="small"
                                    label="Event Tyme"
                                    disabled
                                    value={state.timestamp}
                                />

                                <TextField
                                    size="small"
                                    label="User"
                                    disabled
                                    value={state.user?.username || "anonymous"}
                                />

                                <TextField
                                    size="small"
                                    label="User roles"
                                    disabled
                                    value={state.user?.role?.join(', ') || " "}
                                />

                                <TextField
                                    size="small"
                                    label="User permissions"
                                    disabled
                                    value={state.user?.permissions?.join(', ') || " "}
                                />
                            </div>
                        </Paper>
                    </Box>

                    <Box >
                        <Paper className="p-2">
                            <div className="grid gap-4 p-4 rounded-md">
                                <TextField
                                    size="small"
                                    label="Event Category"
                                    disabled
                                    value={state.category}
                                />

                                <TextField
                                    size="small"
                                    label="Event Type"
                                    disabled
                                    value={state.resource.type}
                                />

                                <TextField
                                    size="small"
                                    label="Operation"
                                    disabled
                                    value={state.operation}
                                />

                                <TextField
                                    size="small"
                                    label="Severity"
                                    disabled
                                    value={state.severity}
                                />

                            </div>
                        </Paper>
                    </Box>
                </div>
                <div className="grid gap-8">
                    {state && (
                        <Box sx={{ width: '100%' }} data-tut="four-step">
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <div>
                                    <div className="compare-rev-div">
                                        <Viewer
                                            className="bg-white text-dark-500"
                                            diff={differ.diff(state.resource.previousState || {}, state.resource.newState || {})}          // required
                                            indent={4}                 // default `2`
                                            lineNumbers={true}         // default `false`
                                            highlightInlineDiff={true} // default `false`
                                            hideUnchangedLines={false}
                                            inlineDiffOptions={{
                                                mode: 'word',            // default `"char"`, but `"word"` may be more useful
                                                wordSeparator: ' ',      // default `""`, but `" "` is more useful for sentences
                                            }}
                                            syntaxHighlight={{
                                                theme: ''
                                            }}
                                        />
                                    </div>
                                </div>
                            </Paper>
                        </Box>

                    )}
                </div>
            </div>
        </div>
    )

}

export default AuditLogDetail