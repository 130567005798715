import { FormControl, TextField } from '@mui/material'
import React from 'react'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const { DateTime } = require("luxon");

const RangeDateFilter = ({
    id,
    name,
    filter,
    placeholder,
    value,
    processFilter,
    updateState,
    required,
    lastCommitedState
}) => {



    return (
        <div className="grid grid-cols-2 gap-4">
            <FormControl variant="outlined" size="small" required={required} fullWidth={true} >
                <LocalizationProvider dateAdapter={AdapterLuxon}   >
                    <DatePicker
                        slotProps={{ textField: { size: 'small' } }}
                        name={name}
                        format="yyyy-MM-dd"
                        id={id}
                        views={['day', 'year']}
                        label={placeholder[0]}
                        value={value[0]}
                        defaultValue={null}
                        minDate={filter.minDate}
                        maxDate={filter.maxDate}
                        onChange={(newValue, dateAsString) => updateState(id, [newValue, value[1]], true)}
                        onAccept={(newValue) => updateState(id, [newValue, value[1]], true)}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} helperText={null} size="small" />}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl variant="outlined" size="small" required={required} fullWidth={true} >
                <LocalizationProvider dateAdapter={AdapterLuxon}   >
                    <DatePicker
                        slotProps={{ textField: { size: 'small' } }}
                        name={name}
                        format="yyyy-MM-dd"
                        id={id}
                        views={['day', 'year']}
                        label={placeholder[1]}
                        value={value[1]}
                        defaultValue={null}
                        minDate={value[0]}
                        maxDate={filter.maxDate}
                        onChange={(newValue, dateAsString) => updateState(id, [value[0], newValue], true)}
                        onAccept={(newValue) => updateState(id, [value[0], newValue], true)}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} helperText={null} size="small" />}
                    />
                </LocalizationProvider>
            </FormControl>
        </div>
    )
}

RangeDateFilter.processInput = (value) => value
RangeDateFilter.getOptionLabel = (value) => value
RangeDateFilter.getOptionId = (value) => value

export default RangeDateFilter