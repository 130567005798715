import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch, Tooltip } from '@mui/material';
import React, { useContext } from 'react'
import { testMetadataMapping, updateMetadataMapper } from './edit-collections-actions';
import { useSelector } from 'react-redux';
import { Editor } from '@monaco-editor/react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import AppContext from '../../state/AppContext';


const EditCollectionMetadataMapper = (props) => {

    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const state = useSelector(state => state.pages.editCollection)
    const metadataMapper = state.metadataMapper;

    return (
        <form>
            <div className="grid gap-8 p-4 ">
                <p className="text-md border-b pb-4 text-gray-800">Metadata mapper allows you to extract information from documents and add them to the <a className=" text-gray-900 underline" href="#">metadata</a> section in order to perform more efficient searches.</p>
                <div className="grid grid-cols-1 gap-4">
                    <div>
                        <FormControlLabel
                            control={<Switch name="enabled" checked={metadataMapper.enabled} onChange={updateMetadataMapper} />}
                            label={metadataMapper.enabled ? "Enabled" : "Disabled"}
                        />

                    </div>
                    {metadataMapper.enabled && (
                        <>
                            <div className="flex gap-4 items-center">
                                <FormControl variant="outlined" size="small" fullWidth={false} className="w-44" >
                                    <InputLabel htmlFor="mapperMode" required={true}>Mapper Mode</InputLabel>
                                    <Select
                                        id="mapperMode"
                                        name="mapperMode"
                                        size="small"
                                        fullWidth={true}
                                        label="Mapper Mode"
                                        value={metadataMapper.mapperMode}
                                        onChange={updateMetadataMapper}
                                    >
                                        <MenuItem value="merge">Merge</MenuItem>
                                        <MenuItem value="override">Override</MenuItem>
                                    </Select>
                                </FormControl>
                                <Tooltip title="Determines how metadata is assigned to the document; merge mixes existing metadata with newly generated metadata; override removes all existing metadata and leaves only the new metadata.">
                                    <HelpOutlineIcon />
                                </Tooltip>
                            </div>
                            <div className="grid gap-4">
                                <div className="grid gap-2">
                                    <p>Document example <Tooltip title="This example corresponds to the new document created"><HelpOutlineIcon /></Tooltip></p>
                                    <Box className="h-full">
                                        <Paper className="h-full">
                                            <Editor
                                                options={{
                                                    selectOnLineNumbers: true,
                                                    automaticLayout: true,
                                                    minimap: {
                                                        enabled: false
                                                    },
                                                    lineNumbers: 'on'
                                                }}
                                                height="400px"
                                                language="json"
                                                theme="git"
                                                className="h-full"
                                                value={metadataMapper.documentExample}
                                                onChange={(newValue, e) => updateMetadataMapper({ target: { name: "documentExample", type: "text", value: newValue } })}
                                            />
                                        </Paper>
                                    </Box>
                                    <p className="text-gray-700 text-xs px-4">Use a real JSON document to validate your rules, use $ to reference to this document</p>
                                </div>
                                <div className="grid gap-2">
                                    <p>Metadata mapper script</p>
                                    <Box className="h-full flex-1">
                                        <Paper className="h-full">
                                            <Editor
                                                options={{
                                                    selectOnLineNumbers: true,
                                                    automaticLayout: true,
                                                    minimap: {
                                                        enabled: false
                                                    },
                                                    lineNumbers: 'on',
                                                    scrollbar: {
                                                        vertical: 'auto',
                                                        horizontal: "auto",
                                                    },
                                                }}
                                                height="500px"
                                                language="java"
                                                defaultLanguage="java"
                                                theme="light"
                                                className="h-full"
                                                value={metadataMapper.expression}
                                                onChange={(newValue, e) => updateMetadataMapper({ target: { name: "expression", type: "text", value: newValue } })}
                                            />
                                        </Paper>
                                    </Box>
                                    <p className="text-gray-700 text-xs px-4">Defines the script to retrieve the metadata of a document</p>
                                </div>
                            </div>
                            {metadataMapper.result && (
                                <div >
                                    <p>Result</p>
                                    <pre className="border-2 rounded-md p-4">
                                        {JSON.stringify(metadataMapper.result.result, null, 2)}
                                    </pre>
                                </div>
                            )}

                            {metadataMapper.result && (
                                <div className={metadataMapper.result.valid ? "bg-green-500 rounded-md p-4" : "bg-red-500 rounded-md p-4"}>
                                    <p className="text-white">{metadataMapper.result.message}</p>
                                </div>
                            )}
                            <div className='flex justify-end'>
                                <Button className="!bg-gray-800 !text-white" onClick={() => testMetadataMapping(project)}>
                                    <PlaylistAddCheckCircleIcon />
                                    <span className="ml-1 text-white">TEST MAPPING</span>
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </form>
    )
}

export default EditCollectionMetadataMapper;