import store from '../../redux/store'
import {
    EDIT_USER_GENERALES,
    EDIT_USER_PERMISSIONS,
    EDIT_USER_RESET,
    EDIT_USER_ROLES
} from '../../redux/redux-const'
import APIInvoke from '../../utils/APIInvoke';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


const getState = () => {
    return store.getState().pages.editUser
}

const merge = (type, param) => {
    store.dispatch({
        type,
        payload: {
            ...getState(),
            ...param
        }
    })
}

export const reset = () => {
    store.dispatch({ type: EDIT_USER_RESET })
}

export const init = async (user) => {
    let responseUser = null
    const responsePermissions = (await (await APIInvoke.invokeGET(`/security/permissions`)).json()).body
    const responseRoles = (await (await APIInvoke.invokeGET(`/security/roles`)).json()).body

    let selectedRoles = null
    let selectedPermissions = null

    if (user !== "_new") {
        responseUser = (await (await APIInvoke.invokeGET(`/users/${user}`)).json()).body

    }

    selectedRoles = responseRoles.map(r => {
        return {
            ...r,
            selected: responseUser && responseUser.roles.findIndex(ur => ur === r.name) !== -1
        }
    })
    selectedPermissions = responsePermissions.map(p => {
        return {
            ...p,
            selected: responseUser && responseUser.permissions.findIndex(up => up === p.name) !== -1
        }
    })


    merge(EDIT_USER_GENERALES, {
        new: user === "_new",
        ...responseUser,
        roles: selectedRoles,
        permissions: selectedPermissions,
        allPermissions: responsePermissions,
        allRoles: responseRoles,
    })
}

export const updateGenerales = (action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value


    if (type === "checkbox") {
        value = action.target.checked
    }
    merge(EDIT_USER_GENERALES, { [name]: value })
}

export const checkRole = (e, index) => {
    merge(EDIT_USER_ROLES, { index })
}

export const checkPermissions = (e, index) => {
    merge(EDIT_USER_PERMISSIONS, { index })
}

export const save = async (e, navigate) => {
    e.preventDefault()
    
    const state = getState()

    if(state.password !== state.password2) {
        toast.error("The two passwords must match")
        return
    }

    const request = {
        username: state.username,
        email: state.email,
        name: state.name,
        password: state.password,
        active: state.active,
        roles: state.roles.filter(r => r.selected).map(r => r.name),
        permissions: state.permissions.filter(p => p.selected).map(p => p.name)
    }

    const response = state.id
        ? await (await APIInvoke.invokePUT(`/users/${state.id}`, request)).json()
        : await (await APIInvoke.invokePOST(`/users`, request)).json()
    if (response.ok) {
        merge(EDIT_USER_GENERALES, {
            new: false,
            id: response.body.id
        })
        toast.success(response.message)
        navigate(`/users/${response.body.id}/edit`)
    } else {
        toast.error(response.message)
    }
}