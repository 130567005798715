import store from '../../redux/store'
import {
    EDIT_PROJECT_GENERALES,
    EDIT_PROJECT_RESET
} from '../../redux/redux-const'
import APIInvoke from '../../utils/APIInvoke';
import toast from 'react-hot-toast';


const getState = () => {
    return store.getState().pages.editProject
}

const merge = (type, param) => {
    store.dispatch({
        type,
        payload: {
            ...getState(),
            ...param
        }
    })
}

export const reset = () => {
    store.dispatch({ type: EDIT_PROJECT_RESET })
}

export const init = async (project) => {
    const response = await (await APIInvoke.invokeGET(`/projects/${project}`)).json()
    merge(EDIT_PROJECT_GENERALES, {
        new: false,
        name: response.body.name,
        tokens: response.body.tokens
    })
}

export const toggleDeleteTokenDialog = (event, row) => {
    const state = getState()
    merge(EDIT_PROJECT_GENERALES, {
        showDeleteDialog: {
            open: !state.showDeleteDialog.open,
            id: row.id
        }
    })
}

export const updateGenerales = (action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value


    if (type === "checkbox") {
        value = action.target.checked
    }

    console.log(name, type, value)
    merge(EDIT_PROJECT_GENERALES, { [name]: value })
}


export const save = async (project, navigate) => {
    const state = getState()
    const request = {
        name: state.name
    }
    const response = await (await APIInvoke.invokePOST(`/projects`, request)).json()
    if (response.ok) {
        merge(EDIT_PROJECT_GENERALES, {
            new: false,
            id: response.body.id,
            name: response.body.name
        })
        toast.success(response.message)
        window.location = `/projects/${state.name}/edit`
    } else {
        toast.error(response.message)
    }


}

export const onDeleteToken = async (project) => {
    const state = getState()

    const response = await (await APIInvoke.invokeDELETE(`/projects/${project}/tokens/${state.showDeleteDialog.id}`)).json()

    merge(EDIT_PROJECT_GENERALES, {
        showDeleteDialog: {
            open: false
        },
        tokens: state.tokens.filter(t => t.id !== state.showDeleteDialog.id)
    })

    if (response.ok) {
        toast.success(response.message)
    } else {
        toast.error(response.message)
    }
}


export const deleteProject = async (e, navigate) => {
    e.preventDefault()

    const state = getState()

    if (state.deleteProjectName !== state.name) {
        toast.error(`The project name does not match`)
        return
    }

    const response = await (await APIInvoke.invokeDELETE(`/projects/${state.name}`)).json()

    merge(EDIT_PROJECT_GENERALES, {
        showDeleteDialog: {
            open: false
        },
        tokens: state.tokens.filter(t => t.id !== state.showDeleteDialog.id)
    })

    if (response.ok) {
        toast.success(response.message)
        window.location = "/"
    } else {
        toast.error(response.message)
    }
}