import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Tabs, Tab, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { checkRole, init, reset, save, updateGenerales } from './edit-user-actions';
import { Box, Paper, TextField, Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import * as Permissions from '../../utils/Permissions'
import GrantContent from '../../template/GrantContent';
import KeyIcon from '@mui/icons-material/Key';
import SourceIcon from '@mui/icons-material/Source';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { setLoading } from '../../template/app-actions';
import toast from 'react-hot-toast';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';

const EditUser = (props) => {

    const navitage = useNavigate()
    const state = useSelector(state => state.pages.editUser)
    const [tab, setTab] = useState(0)
    let { user } = useParams()

    useEffect(() => {
        init(user)
        return () => reset()
    }, [user])

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const _save = async (event) => {
        try {
            event.preventDefault()
            setLoading(true)
            await save(event, navitage)
        } catch (error) {
            console.error(error)
            toast.error("Error to perform action")
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="">
            <Header title={<span className="capitalize">{state.username}<span className="text-xs text-gray-700">/ {user} </span></span>} >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Users</span></Typography>
                    <Typography ><span className="text-gray-600">{state.username}</span></Typography>
                </Breadcrumbs>
            </Header>
            <div className="content grid gap-8">
                <form onSubmit={_save}>
                    <div className="grid gap-8">
                        <div className=" ">
                            <Box>
                                <Paper>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
                                                <Tab label="General" icon={<SourceIcon />} iconPosition="start" />
                                                <Tab label="Permissions" icon={<LocalPoliceIcon />} iconPosition="start" disabled={state.new} />
                                                <Tab label="Credentials" icon={<KeyIcon />} iconPosition="start" disabled={state.new} />
                                            </Tabs>
                                        </Box>
                                    </Box>

                                    {tab === 0 && (
                                        <div className="grid grid-cols-2">
                                            <div className="grid gap-4 p-4">
                                                <TextField
                                                    required
                                                    disabled={!state.new}
                                                    size="medium"
                                                    label="Username"
                                                    name="username"
                                                    onChange={updateGenerales}
                                                    value={state.username}
                                                />
                                                <TextField
                                                    required
                                                    size="medium"
                                                    label="Name"
                                                    name="name"
                                                    onChange={updateGenerales}
                                                    value={state.name}
                                                />
                                                <TextField
                                                    required
                                                    size="medium"
                                                    label="Email"
                                                    name="email"
                                                    type="email"
                                                    onChange={updateGenerales}
                                                    value={state.email}
                                                />


                                                <FormControl >
                                                    <FormControlLabel
                                                        control={<Checkbox disabled={state.master} checked={state.active} />}
                                                        checked={state.active}
                                                        name="active"
                                                        label={"Active"}
                                                        labelPlacement="end"
                                                        onClick={updateGenerales}
                                                    />
                                                </FormControl>
                                            </div>

                                        </div>
                                    )}
                                    {tab === 1 && (
                                        <div className="p-4">
                                            <div className="grid grid-col-2 md: grid-col-1 gap-2">
                                                <p>Select the user roles</p>
                                                {state.roles.map((rol, index) => (
                                                    <FormControl key={rol.name} disabled={state.master}>
                                                        <FormControlLabel
                                                            disabled={state.master}
                                                            control={<Checkbox disabled={state.master} />}
                                                            checked={rol.selected}
                                                            label={rol.name}
                                                            onClick={e => checkRole(e, index)}
                                                            labelPlacement="end"
                                                        />
                                                        <FormHelperText>{rol.descripcion}</FormHelperText>
                                                    </FormControl>
                                                ))}
                                                {/* 
                                                    <div className="grid gap-1">
                                                        <p>Permissions</p>
                                                        {state.permissions.map((permission, index) => (
                                                            <FormControl key={permission.name} >
                                                                <FormControlLabel
                                                                    value="end"
                                                                    labelPlacement="end"
                                                                    disabled={state.master}
                                                                    control={<Checkbox />}
                                                                    checked={permission.selected}
                                                                    label={permission.name}
                                                                    onClick={e => checkPermissions(e, index)}
                                                                />
                                                                <FormHelperText>{permission.descripcion}</FormHelperText>
                                                            </FormControl>
                                                        ))}
                                                    </div>
                                                    */}
                                            </div>
                                        </div>
                                    )}
                                    {tab === 2 && (
                                        <div className="grid grid-cols-2 gap-4 p-4">
                                            <div className="grid gap-4">
                                                {state.id && (
                                                    <div>
                                                        <span>Change password </span>
                                                        <Tooltip title="Leave the password blank if you do not want to change the user's password, otherwise you will change the password" >
                                                            <ErrorOutlineIcon fontSize="small" />
                                                        </Tooltip>
                                                    </div>
                                                )}
                                                <TextField
                                                    required
                                                    size="medium"
                                                    label="Password"
                                                    name="password"
                                                    type="password"
                                                    onChange={updateGenerales}
                                                    value={state.password}
                                                />
                                                <TextField
                                                    required
                                                    size="medium"
                                                    label="Repeat Password"
                                                    name="password2"
                                                    type="password"
                                                    onChange={updateGenerales}
                                                    value={state.password2}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Paper>
                            </Box>
                        </div>
                        <div className="text-right">
                            <Button variant="contained" type="submit" className="text-dark-500 py-10" >SAVE</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditUser