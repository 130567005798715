import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, FormControl, FormControlLabel, Paper, Switch, TextField, Tooltip } from '@mui/material';
import React, { useContext, useState } from 'react'
import { deleteRule, testRule, updateRule } from './edit-collections-actions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSelector } from 'react-redux';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import AppContext from '../../state/AppContext';
import { useParams } from 'react-router-dom';
import { Editor } from '@monaco-editor/react';
import ConfirmDialog from '../../template/ConfirmDialog';


const EditCollectionExpressionRule = ({ rule }) => {

    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const { collection } = useParams()

    const state = useSelector(state => state.pages.editCollection)
    const rulesConfig = state.rulesConfig;

    const [uiState, setUiState] = useState({
        showDeleteDialog: {
            open: false
        }
    })


    const toggleDeleteDialog = () => {
        setUiState({
            ...uiState,
            showDeleteDialog: {
                open: !uiState.showDeleteDialog.open
            }
        })
    }

    return (
        <>
            <ConfirmDialog title="Delete rule" message="do you want to delete the rule?" open={uiState.showDeleteDialog.open} onClose={toggleDeleteDialog} onConfirm={(e) => deleteRule(rule)} />
            <Accordion expanded={true} >
                <AccordionSummary className="!bg-gray-100 border-b !cursor-default" id={rule.id}  >
                    <div className="w-full flex flex-row items-center justify-between">
                        <span>{rule.name ? rule.name : rule.id}</span>
                        <FormControlLabel control={<Switch name="enable" checked={rule.enable} onChange={e => updateRule(rule, e)} />} label={rule.enable ? "Enabled" : "Disabled"} />
                    </div>
                </AccordionSummary>
                <AccordionDetails className="p-4">
                    <div className="grid gap-4 py-4">
                        <FormControl required>
                            <TextField
                                required
                                style={{ fontSize: "12px" }}
                                size="medium"
                                name="name"
                                label="Rule Name"
                                multiline
                                value={rule.name}
                                onChange={e => updateRule(rule, e)}
                            />
                        </FormControl>

                        <FormControl required variant="outlined" size="large" fullWidth={true}>
                            <TextField
                                required
                                style={{ fontSize: "12px" }}
                                size="medium"
                                name="description"
                                label="Rule Description"
                                multiline
                                rows={2}
                                value={rule.description}
                                helperText={"This description is helpful for to user to identify the importance of the rule"}
                                onChange={e => updateRule(rule, e)}
                            />
                        </FormControl>


                        <div className="flex flex-rows gap-4">
                            <div className="flex-1 pb-4">
                                <Box className="h-full">
                                    <Paper className="h-full">
                                        <Editor
                                            options={{
                                                selectOnLineNumbers: true,
                                                automaticLayout: true,
                                                minimap: {
                                                    enabled: false
                                                },
                                                lineNumbers: 'on',
                                                scrollbar: {
                                                    vertical: 'auto',
                                                },

                                            }}
                                            height="100px"
                                            language="sql"
                                            defaultLanguage="sql"
                                            theme="light"
                                            className="h-full"
                                            value={rule.expression}
                                            onChange={(newValue, e) => updateRule(rule, { target: { name: "expression", type: "text", value: newValue } })}
                                        />
                                    </Paper>
                                </Box>
                                <p className="text-gray-700 text-xs px-4 pt-2">Define the rules by which the rules are activated</p>
                            </div>

                        </div>
                        {rule.testResult ? <span className={rule.testResult.valid ? "bg-green-500 text-white rounded-sm py-1 px-2" : "bg-red-500 text-white rounded-sm py-1 px-2"}>{rule.testResult.message}<br />Result: {`${rule.testResult.result}`}</span> : <div />}
                    </div>

                </AccordionDetails>
                <AccordionActions className="bg-gray-100 border-t w-full">
                    <div className="flex flex-row items-center justify-between w-full">

                        <div className="flex flex-row gap-4 justify-end w-full">
                            <Tooltip title="Test rule">
                                <Button className="!bg-gray-800 !text-white" onClick={() => testRule(rule, project, collection)}>
                                    <PlaylistAddCheckCircleIcon />
                                    <span className="ml-1 text-white">TEST RULE</span>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Delete rule">
                                <Button className=" !bg-red-500 !text-white" onClick={toggleDeleteDialog} >
                                    <DeleteForeverIcon className="" />
                                    <span className="ml-1 text-white">DELETE RULE</span>
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </AccordionActions>
            </Accordion>
        </>
    )
}

export default EditCollectionExpressionRule;