import config from '../config'



class APIInvoke {

    async getAPIHeader() {
        const deviceInfo = {
            userAgent: navigator.userAgent,
            platform: navigator.userAgentData.platform,
            language: navigator.language,
            mobile: navigator.userAgentData.mobile,
            brand: navigator.userAgentData.brands[0]?.brand || null,
            version: navigator.userAgentData.brands[0]?.version || null,
            vendor: navigator.userAgentData.vendor || null,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        const token = window.localStorage.getItem("token");
        let headers = {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
            "mode": "cors",
            'X-Device-Info': btoa(JSON.stringify(deviceInfo)),
            'X-Correlation-ID': crypto.randomUUID()
        };

        const location = await this.getLocation();
        if (location) {
            headers['X-Location'] = btoa(JSON.stringify(location));
        }

        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        return headers;
    }

    async getLocation() {
        try {
            const pos = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            return {
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                accuracy: pos.coords.accuracy,
                altitude: pos.coords.altitude,
            };
        } catch (e) {
            /*switch (error.code) {
                case error.PERMISSION_DENIED:
                    throw new Error('Necesitas permitir el acceso a tu ubicación para usar la aplicación');
                case error.POSITION_UNAVAILABLE:
                    throw new Error('La información de ubicación no está disponible');
                case error.TIMEOUT:
                    throw new Error('Se agotó el tiempo para obtener la ubicación');
                default:
                    throw new Error('Error al obtener la ubicación');
            }*/
            return null;
        }
    };

    async invokeGET(url) {
        const params = {
            method: 'get',
            headers: await this.getAPIHeader()
        };
        return this.invoke(url, params);
    }

    async invokePUT(url, body) {
        const params = {
            method: 'put',
            headers: await this.getAPIHeader(),
            body: JSON.stringify(body)
        };
        return this.invoke(url, params);
    }

    async invokePOST(url, body) {
        const params = {
            method: 'post',
            headers: await this.getAPIHeader(),
            body: JSON.stringify(body)
        };
        return this.invoke(url, params);
    }

    async invokeDELETE(url) {
        const params = {
            method: 'delete',
            headers: await this.getAPIHeader()
        };
        return this.invoke(url, params);
    }

    async invoke(url, params) {
        const completeURL = url.toLowerCase().startsWith("http");
        const finalURL = completeURL ? url : `${config.api.baseUrl}${url}`;
        return fetch(finalURL, params);
    }

}

const api = new APIInvoke()
export default api;
