import { Divider, IconButton, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../state/UserContext';
import { useSelector } from 'react-redux';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';


const Header = ({ title, children, backLink }) => {

    const appStore = useSelector(state => state.pages.app)
    const { user } = useContext(UserContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { state } = useLocation();



    const getBackLink = () => {

        if (state && state.backLink) {
            return state.backLink;
        } else if (backLink) {
            return backLink;
        } else {
            return null;
        }
    }

    const _backLink = getBackLink();

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onLogout = () => {
        window.localStorage.removeItem("token")
        navigate("/login")
    }

    const onProfile = () => {
        handleCloseMenu()
        navigate("/profile")
    }

    return (
        <div className="bg-dark-900">
            <div className="layout grid  ">
                <div className="h-16 pr-8 flex flex-row justify-between items-center border-b border-dark-400">
                    <div className=" px-4 flex flex-row items-center">
                        {_backLink !== null && (
                            <IconButton onClick={() => navigate(_backLink)} color="primary">
                                <ArrowBackIcon className="text-green" />
                            </IconButton>
                        )}
                        <Typography variant="h4" component="h4" className="text-gray-200">
                            {title}
                        </Typography>
                    </div>

                    <div>
                        <IconButton size="large" color="primary"  >
                            <MoveToInboxIcon className="text-white" />
                        </IconButton>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenMenu}
                            color="primary"
                        >
                            <AccountCircle className="text-white" />
                        </IconButton>
                    </div>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem disabled>
                            <ListItemText >
                                <p>{user.name}</p>
                            </ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={onProfile}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Profile</Typography>
                        </MenuItem>
                        <MenuItem onClick={onLogout}>
                            <ListItemIcon>
                                <MeetingRoomIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Logout</Typography>
                        </MenuItem>
                    </Menu>
                </div>
                <div className="!relative">
                    {appStore.loading && <LinearProgress variant="indeterminate" className="!absolute !top-0 !left-0 !right-0" />}
                </div>
                <div className="border-b border-dark-300 bg-dark-600">
                    <div className="margin p-2">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header