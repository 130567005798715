import { Box, Breadcrumbs, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, Button, LinearProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import ConfirmDialog from '../../template/ConfirmDialog';
import { toast } from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import AppContext from '../../state/AppContext';
import WarningIcon from '@mui/icons-material/Warning';
import { DateTime } from 'luxon';
import { setLoading } from '../../template/app-actions';
import * as Permissions from '../../utils/Permissions'
import GrantContent from '../../template/GrantContent';
import Header from '../../template/Header';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { fromISOToUTCFormat } from '../../utils/DateUtilsUTC';
import QueryBuilder, { getOperators, keyOperators } from '../../components/QueryBuilder/QueryBuilder';
import { useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';

const columns = [
    {
        field: 'key',
        headerName: 'Key',
        align: "left"
    }, {
        field: 'datetime',
        headerName: 'Update At',
        align: "left"
    }, {
        field: 'user',
        headerName: 'Updated By',
        align: "left"
    }, {
        field: 'revision',
        headerName: 'Revisions',
        align: "left"
    }, {
        field: 'errors',
        headerName: 'Errors',
        align: "center"
    }, {
        field: 'action',
        headerName: 'Action',
        align: "right"
    }
]

// inputType: number, time, datetime-local, date
// valueEditorType: 'textarea'


const initialQuery = {
    combinator: 'and',
    rules: [
        {
            field: 'lastReceivedAt',
            operator: '>=',
            value: DateTime.now().minus({ days: 1 }).startOf("day").toFormat("yyyy-MM-dd'T'HH:mm") // "2024-10-30T12:00"
        },
    ],
};

const initialState = {
    pageSize: 10,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
    query: undefined,
    queryBuilder: undefined,
    showDeleteDialog: {
        open: false,
        payload: null
    }
}

const restoreQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let query = undefined;
    if (searchParams.get('query')) {
        query = JSON.parse(atob(searchParams.get('query')));
    }

    const currentPage = searchParams.get('currentPage') || 0;
    const pageSize = searchParams.get('pageSize') || 10;

    const newState = {
        ...initialState,
        query: query ? query.query : undefined,
        queryBuilder: query ? query.queryBuilder : initialQuery,
        currentPage,
        pageSize
    };
    return newState;
}



const Collection = (props) => {

    let { collection } = useParams()
    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const navigate = useNavigate()
    const location = useLocation();
    const { loading } = useSelector(state => state.pages.app)
    const [state, setState] = useState(restoreQueryParams())
    const [fields, setFields] = useState([{ name: 'key', label: 'Key', inputType: "string", operators: keyOperators, defaultOperator: "=" }])
    const [query, setQuery] = useState(restoreQueryParams().queryBuilder)

    useEffect(() => {
        fetchCollection()
    }, [])

    /*
    useEffect(() => {
        if (!loading || !state.query) {
            fetchDocuments(state)
        }
    }, [state.query, state.queryBuilder, state.currentPage, state.pageSize])
    */

    const fetchCollection = async () => {
        try {
            const response = await (await APIInvoke.invokeGET(`/projects/${project}/collections/${collection}`)).json()
            if (response.ok) {
                setState({
                    ...state,
                });
                setFields(response.body.schema.fields.map(field => ({
                    name: field.fieldName,
                    placeholder: field.placeholder,
                    label: field.fieldName,
                    inputType: field.type === "datetime" ? "datetime-local" : field.type,
                    valueEditorType: field.type === "datetime" ? "datetime-local" : field.type,
                    operators: getOperators(field.type)
                })));
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.error("Error lo load collection", error)
        } finally {
            //setLoading(false)
        }
    }

    const handleChangePage = (e, newPage) => {
        setQueryParam('currentPage', newPage)
        const newState = {
            ...state,
            currentPage: newPage
        }
        setState(newState)
        fetchDocuments(newState)
    }

    const onRowsPerPageChange = (event) => {
        setQueryParam('pageSize', event.target.value)
        const newState = {
            ...initialState,
            items: state.items,
            pageSize: parseInt(event.target.value, 10),
        }
        setState(newState)
        fetchDocuments(newState)
    }

    const onRowClick = (event, row) => {
        navigate(`/projects/${project}/collections/${collection}/documents/${row.id}`, { state: { backLink: -1 } })
    }

    const onDelete = async () => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeDELETE(`/projects/${project}/collections/${collection}/documents/${state.showDeleteDialog.payload.id}`)).json()
            if (response.ok) {
                toast.success(response.message)
                setState({
                    ...state,
                    items: state.items.filter(x => x.id !== state.showDeleteDialog.payload.id),
                    showDeleteDialog: { open: false, payload: null }
                })
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            toast.error("Unxpected error")
        } finally {
            setLoading(false)
        }

    }

    const toggleDeleteDialog = (event, payload) => {
        event.stopPropagation()
        setState({
            ...state,
            showDeleteDialog: {
                open: !state.showDeleteDialog.open,
                payload
            }
        })
    }

    const onApply = (query, queryBuilder) => {
        const newState = {
            ...state,
            query,
            queryBuilder
        }
        setState(newState)
        setQuery(queryBuilder)

        const query64 = btoa(JSON.stringify({
            query,
            queryBuilder
        }));
        setQueryParam('query', query64);
        fetchDocuments(newState);
    }

    const setQueryParam = (key, value, replace = true) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        navigate(`?${searchParams.toString()}`);
    }

    const fetchDocuments = async (newState) => {
        try {
            setLoading(true);
            const query64 = btoa(newState.query);
            const startTime = DateTime.now()
            const rawResponse = await APIInvoke.invokeGET(`/projects/${project}/collections/${collection}/documents/query?query=${query64}&page=${newState?.currentPage || 0}&size=${newState?.pageSize || 10}&projection=id,key,project,collection,lastUpdateBy,lastUpdateAt,lastReceivedAt,lastRevision,schemaErrors,isValid`)
            if (rawResponse.status === 408) {
                toast.error("Timeout to execute query, try create a more specific query, the response time was " + DateTime.now().diff(startTime, 'seconds').seconds + " seconds");
                return;
            }
            const response = await (rawResponse).json()
            if (response.ok) {
                setState({
                    ...newState,
                    items: response.body.items,
                    totalItems: response.body.totalItems,
                    currentPage: response.body.currentPage,
                    totalPages: response.body.totalPages,
                    pageSize: response.body.pageSize
                });
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.error("Error lo load page", error)
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="">
            <ConfirmDialog title="Delete document" message="This action is permanent and there will be no way to recover the document" open={state.showDeleteDialog.open} onClose={toggleDeleteDialog} onConfirm={onDelete} />
            <Header title="Collection" backLink={`/projects/${project}/collections`} >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600" >
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Collections</span></Typography>
                    <Typography ><span className="text-gray-600">{collection}</span></Typography>
                </Breadcrumbs>
            </Header>
            <div className="content grid gap-8">
                <div className="layout grid gap-4">
                    <div className="flex justify-between items-center">
                        <h4 className="capitalize">
                            {collection}
                        </h4>
                        <GrantContent permisos={[Permissions.COLLECTIONS_W]}>
                            <Button variant="contained" LinkComponent={Link} to={`/projects/${project}/collections/${collection}/edit`}><SettingsIcon className="mr-1" /> CONFIGURE</Button>
                        </GrantContent>
                    </div>
                </div>
                <div className="grid gap-8">
                    <Box>
                        <Paper>
                            <QueryBuilder
                                fields={fields}
                                initialQuery={query}
                                onApply={onApply}
                                fireInitialApply={true}
                                debug={true}
                            />

                            {loading && <LinearProgress size="sm" />}
                            <TableContainer style={{ maxHeight: "calc( 100vh - 270px )" }}>
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((headCell, index) => (
                                                <TableCell key={index} align={headCell.align} padding={'normal'} >
                                                    {headCell.headerName}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.items === undefined
                                            ? (
                                                <TableRow >
                                                    <TableCell colSpan={6}>
                                                        <Box>
                                                            <div className="p-4">
                                                                <LinearProgress />
                                                            </div>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                state.items.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={(event) => onRowClick(event, row)}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell align="left">{row.key}</TableCell>
                                                            <TableCell align="left">{fromISOToUTCFormat(row.lastReceivedAt, 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                            <TableCell align="left">{row.lastUpdateBy}</TableCell>
                                                            <TableCell align="left" width={10} >{row.lastRevision}</TableCell>
                                                            <TableCell align="center" width={10} >
                                                                {!row.valid && (
                                                                    <Tooltip title={row.schemaErrors} >
                                                                        <WarningIcon fontSize="small" className="text-red" />
                                                                    </Tooltip>
                                                                )}
                                                            </TableCell>
                                                            <TableCell width={10} align="right">
                                                                <Tooltip title="Edit">
                                                                    <IconButton onClick={(event) => toggleDeleteDialog(event, row)} ><DeleteIcon className="hover:text-red" /></IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {loading && <LinearProgress size="sm" />}
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                rowsPerPage={state.pageSize}
                                count={state.totalItems}
                                page={state.currentPage}
                                disabled={loading}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={onRowsPerPageChange}
                            />

                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Collection