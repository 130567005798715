import { DateTime } from "luxon";
import {
    EDIT_TOKEN_RESET,
    EDIT_TOKEN_GENERALES,
    EDIT_TOKEN_ROLES,
    EDIT_TOKEN_PERMISSIONS
} from "../redux-const";

const initialState = {
    loading: false,
    tab: 0,
    new: true,
    name: "",
    expirationOpt: "",
    expiration: DateTime.now(),
    permissions: [],
    roles: []
}


export const reducer = (state = initialState, action) => {
    const payload = action.payload;
    const roles = state.roles
    const permission = state.permissions

    switch (action.type) {
        case EDIT_TOKEN_GENERALES:
            return {
                ...state,
                ...payload
            }
        case EDIT_TOKEN_RESET:
            return initialState
        case EDIT_TOKEN_ROLES:
            roles[payload.index].selected = !roles[payload.index].selected
            return {
                ...state,
                roles: [...state.roles]
            }
        case EDIT_TOKEN_PERMISSIONS:
            permission[payload.index].selected = !permission[payload.index].selected
            return {
                ...state,
                roles: [...state.roles]
            }
        default:
            return state
    }
};

export default reducer;
