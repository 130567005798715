import { Box, Breadcrumbs, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { Link, useNavigate } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../template/ConfirmDialog';
import { toast } from 'react-hot-toast';
import AppContext from '../../state/AppContext';
import { setLoading } from '../../template/app-actions';
import * as Permissions from '../../utils/Permissions'
import GrantContent from '../../template/GrantContent';
import FilterBuilder from '../../components/FilterBuilder/FilterBuilder';
import TextFilter from '../../components/FilterBuilder/controls/TextFilter';
import { hasText } from '../../utils/StringUtils';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';
import DataObjectIcon from '@mui/icons-material/DataObject';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import CodeIcon from '@mui/icons-material/Code';

const columns = [
    {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        align: 'left'
    },{
        field: 'name',
        headerName: 'Name',
        type: 'string',
        align: 'left'
    }, {
        field: 'lastPing',
        headerName: 'Last Ping',
        type: 'date',
        align: 'left'
    }, {
        field: 'lastSuccessPing',
        headerName: 'Last Success Ping',
        type: 'date',
        align: 'left'
    }, {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        align: 'left'
    }, {
        field: '',
        headerName: 'Actions',
        type: 'string',
        align: 'right'
    }
]

const filters = [
    {
        id: "name",
        name: "Name",
        placeholder: "Name",
        filter: "name[cn]",
        type: TextFilter,
        defaultValue: ""
    }
]

const AppMonitoring = (props) => {


    const navigate = useNavigate();
    const [data, setData] = useState({
        queryBuilder: {
            queryString: `?sort=name,asc`
        },
        pageSize: 10,
        totalItems: 0,
        currentPage: 0,
        totalPages: 0,
        items: []
    })
    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const [showDeleteDialog, setShowDeleteDialog] = useState({ open: false, payload: null })

    useEffect(() => {
        try {
            setLoading(true)
            init(data)
        } catch (error) {
            console.error("Projects.useEffect => ", error)
        } finally {
            setLoading(false)
        }
    }, [project, data.currentPage, data.pageSize])

    const init = async (newState) => {
        try {
            setLoading(true)
            const query = newState.queryBuilder.queryString

            const response = await (await APIInvoke.invokeGET(`/projects/${project}/monitoring/apps/http${newState.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${newState.currentPage}&sort=name,desc`)).json()
            if (response.ok) {
                setData({
                    ...newState,
                    items: response.body.items,
                    totalItems: response.body.totalItems,
                    currentPage: response.body.currentPage,
                    totalPages: response.body.totalPages,
                    pageSize: response.body.pageSize
                });
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.error("Error lo load page", error)
        } finally {
            setLoading(false)
        }
    }

    const onFilterProcess = async (queryBuilder) => {
        const newState = {
            ...data,
            queryBuilder: queryBuilder,
            totalItems: 0,
            currentPage: 0,
            totalPages: 0,
            pageSize: 10,
        }
        setData(newState)
        init(newState)
    }


    const onRowClick = (event, row) => {
        navigate(`/projects/${project}/monitoring/apps/${row.id}`)
    }

    const onEditClick = (event, row) => {
        event.stopPropagation()
        navigate(`/projects/${project}/monitoring/apps/${row.id}/edit`)
    }

    const onDelete = async () => {
        const response = await (await APIInvoke.invokeDELETE(`/projects/${project}/monitoring/apps/${showDeleteDialog.payload.name}`)).json()
        if (response.ok) {
            toast.success(response.message)
            setData({
                ...data,
                items: data.items.filter(x => x.name !== showDeleteDialog.payload.name)
            })
            setShowDeleteDialog({ open: false, payload: null })
        } else {
            toast.error(response.message)
        }
    }

    const toggleDeleteDialog = (event, payload) => {
        event.stopPropagation()
        setShowDeleteDialog({
            open: !showDeleteDialog.open,
            payload
        })
    }

    const handleChangePage = (e, newPage) => {
        setData({
            ...data,
            currentPage: newPage
        })
    }

    const getPulse = (status) => {
        let color = "";
        if (status === "UP") {
            color = "bg-green"
        } else if (status === "DOWN") {
            color = "bg-red"
        } else if (status === "WARN") {
            color = "bg-orange"
        } else {
            color = "bg-gray"
        }

        
        return (
            <span className="relative flex h-3 w-3">
                <span className={`animate-ping absolute inline-flex h-full w-full rounded-full ${color}-400 opacity-75`}></span>
                <span className={`relative inline-flex rounded-full h-3 w-3 ${color}-500`}></span>
            </span>
        )
    }
    
    const getTypeIcon = (type) => {
        switch(type) {
            case "REST_APP_MONITOR" : return <DataObjectIcon />
            case "SOAP_APP_MONITOR" : return <CodeIcon />
            default: return <MonitorHeartIcon />
        }
    }

    return (
        <div className="">
            <div className="hidden bg-red-500 bg-red-400 bg-orange-500 bg-orange-400 bg-green-500 bg-green-400 bg-gray-500 bg-gray-400" />
            <ConfirmDialog title="Delete app monitoring" message="This action is permanent and there will be no way to recover the app monitoring" open={showDeleteDialog.open} onClose={toggleDeleteDialog} onConfirm={onDelete} />
            <Header title="App Monitoring" >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">App Monitoring</span></Typography>
                </Breadcrumbs>
            </Header>

            <div className="content ">
                <div className="grid gap-8">
                    <div className="text-right">
                        <GrantContent permisos={[Permissions.APP_MONITORING_W]}>
                            <Button variant="contained" LinkComponent={Link} to={`/projects/${project}/monitoring/apps/_new/edit`}><AddIcon /> NEW APP MONITORING</Button>
                        </GrantContent>
                    </div>
                    <div >
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <div>
                                    <FilterBuilder filters={filters} onProcess={onFilterProcess} />
                                </div>
                                <TableContainer>
                                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((headCell) => (
                                                    <TableCell key={headCell.field} align={headCell.align} padding={'normal'} >
                                                        <TableSortLabel >
                                                            {headCell.headerName}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.items.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => onRowClick(event, row)}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell width={30} align="left">
                                                            <p>{getTypeIcon(row.type)}</p>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <p>{row.name}</p>
                                                        </TableCell>
                                                        <TableCell align="left">{row.lastPing}</TableCell>
                                                        <TableCell align="left">{row.lastSuccessPing}</TableCell>
                                                        <TableCell align="left">
                                                            <div className="flex flex-row gap-2 items-center">
                                                                {getPulse(row.status)}
                                                                <p>{row.status}</p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell width={130} align="right">
                                                            <GrantContent permisos={[Permissions.APP_MONITORING_W]}>
                                                                <Tooltip title="Edit">
                                                                    <IconButton onClick={(event) => onEditClick(event, row)} ><EditNoteIcon className="hover:text-green" /></IconButton>
                                                                </Tooltip>
                                                            </GrantContent>

                                                            <GrantContent permisos={[Permissions.APP_MONITORING_D]}>
                                                                <Tooltip title="Delete">
                                                                    <IconButton onClick={(event) => toggleDeleteDialog(event, row)} ><DeleteIcon className="hover:text-red" /></IconButton>
                                                                </Tooltip>
                                                            </GrantContent>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50]}
                                    component="div"
                                    rowsPerPage={data.pageSize}
                                    count={data.totalItems}
                                    page={data.currentPage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={() => { }}
                                />
                            </Paper>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppMonitoring 