import { Box, Button, Breadcrumbs, Checkbox, FormControlLabel, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography, LinearProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import APIInvoke from '../../utils/APIInvoke';
import AddIcon from '@mui/icons-material/Add';
import AppContext from '../../state/AppContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../template/ConfirmDialog';
import { toast } from 'react-hot-toast';
import LensIcon from '@mui/icons-material/Lens';
import FilterBuilder from '../../components/FilterBuilder/FilterBuilder';
import TextFilter from '../../components/FilterBuilder/controls/TextFilter';
import { hasText } from '../../utils/StringUtils';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';
import { useSelector } from 'react-redux';
import { setLoading } from '../../template/app-actions';

const filters = [
    {
        id: "username",
        name: "username",
        placeholder: "Username",
        filter: "username[cn]",
        type: TextFilter,
        defaultValue: ""
    }, {
        id: "email",
        name: "email",
        placeholder: "Email",
        filter: "email[cn]",
        type: TextFilter,
        defaultValue: ""
    }
]

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        type: 'string',
        align: 'left'
    }, {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        align: 'left'
    }, {
        field: 'username',
        headerName: 'Username',
        type: 'string',
        align: 'left'
    }, {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        align: 'left'
    }, {
        field: 'active',
        headerName: 'Status',
        type: 'string',
        align: 'left'
    }, {
        field: '',
        headerName: 'Actions',
        type: 'string',
        align: 'right'
    }
]

const initialState = {
    queryBuilder: {
        queryString: ``
    },
    pageSize: 10,
    totalItems: 0,
    currentPage: 0,
    items: []
}

const Users = (props) => {

    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const navigate = useNavigate();
    const [data, setData] = useState(initialState)
    const [showDeleteDialog, setShowDeleteDialog] = useState({ open: false, payload: null })
    const { loading } = useSelector(state => state.pages.app)

    useEffect(() => {
        try {
            setLoading(true)
            init(data)
        } catch (error) {
            console.log("Projects.useEffect => ", error)
        } finally {
            setLoading(false)
        }
    }, [data.currentPage, data.pageSize])

    const onFilterProcess = async (queryBuilder) => {
        console.log("queryBuilder", queryBuilder)
        const newState = {
            ...data,
            queryBuilder: queryBuilder,
            totalItems: 0,
            currentPage: 0,
            totalPages: 0,
            pageSize: 10,
        }
        setData(newState)
        init(newState)
    }

    useEffect(() => {
        init(data)
    }, [data.currentPage, data.pageSize])

    const init = async (newState) => {
        try {
            setLoading(true)
            const query = newState.queryBuilder.queryString
            const response = await (await APIInvoke.invokeGET(`/users${newState.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${newState.currentPage}&size=${newState.pageSize}&sort=lastReceivedAt,desc`)).json()
            //const response = await (await APIInvoke.invokeGET(`/users?page=${newState.currentPage}&size=20`)).json()
            setData({
                ...newState,
                items: response.body.items,
                totalItems: response.body.totalItems,
                currentPage: response.body.currentPage,
                totalPages: response.body.totalPages,
                pageSize: response.body.pageSize
            })
        } catch (error) {
            console.error("Users.init => ", error)
        } finally {
            setLoading(false)
        }

    }

    const onDelete = async () => {
        const response = await (await APIInvoke.invokeDELETE(`/users/${showDeleteDialog.payload.id}`)).json()
        if (response.ok) {
            toast.success(response.message)
            setData({
                ...data,
                items: data.items.filter(x => x.id !== showDeleteDialog.payload.id)
            })
            setShowDeleteDialog({ open: false, payload: null })
        } else {
            toast.error(response.message)
        }
    }

    const onRowClick = (event, row) => {
        navigate(`/users/${row.id}/edit`, { state: { backLink: -1 } })
    }

    const toggleDeleteDialog = (event, payload) => {
        event.stopPropagation()
        setShowDeleteDialog({
            open: !showDeleteDialog.open,
            payload
        })
    }

    const handleChangePage = (e, newPage) => {
        setData({
            ...data,
            currentPage: newPage
        })
    }

    const onRowsPerPageChange = (event) => {
        setData({
            ...initialState,
            items: data.items,
            pageSize: parseInt(event.target.value, 10)
        })
    }

    return (
        <div className="">
            <ConfirmDialog title="Delete user" message="This action is permanent and there will be no way to recover the user" open={showDeleteDialog.open} onClose={toggleDeleteDialog} onConfirm={onDelete} />
            <Header title="Users" >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Users</span></Typography>
                </Breadcrumbs>
            </Header>
            <div className="content grid gap-8">
                <div className="text-right">
                    <Button variant="contained" LinkComponent={Link} to={`/users/_new/edit`} state={{ backLink: -1 }}><AddIcon /> NEW USER</Button>
                </div>
                <div >
                    <Box >
                        <Paper >
                            <div>
                                <FilterBuilder filters={filters} onProcess={onFilterProcess} />
                            </div>
                            {loading && <LinearProgress size="sm" />}
                            <TableContainer>
                                <Table aria-labelledby="tableTitle" size={"small"}  >
                                    <TableHead >
                                        <TableRow >
                                            {columns.map((headCell) => (
                                                <TableCell key={headCell.field} align={headCell.align} padding={'normal'} >
                                                    <TableSortLabel >
                                                        {headCell.headerName}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.items.map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => onRowClick(event, row)}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell align="left">{row.id}</TableCell>
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell align="left">{row.username}</TableCell>
                                                    <TableCell align="left">{row.email}</TableCell>
                                                    <TableCell align="left">
                                                        <span className="flex gap-2">
                                                            <LensIcon className={row.active ? "text-green" : "text-red"} fontSize="small" />
                                                            {row.active ? "Active" : "Inactive"}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell width={130} align="right">
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => navigate(`/users/${row.id}/edit`, { state: { backLink: -1 } })} ><EditNoteIcon /></IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={(event) => toggleDeleteDialog(event, row)} ><DeleteIcon className="hover:text-red" /></IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {loading && <LinearProgress size="sm" />}
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                rowsPerPage={data.pageSize}
                                count={data.totalItems}
                                page={data.currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={onRowsPerPageChange}
                            />
                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Users 