import React, { useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Box, Breadcrumbs, Chip, IconButton, List, ListItemButton, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material'
import SyntaxHighlighter from 'react-syntax-highlighter';
import CompareIcon from '@mui/icons-material/Compare';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// https://json-diff-kit.js.org/#l=%7B%0A++%22noPoliza%22%3A+%221234567890%22%2C%0A++%22total%22%3A+100000%2C%0A++%22iva%22%3A+10000%2C%0A++%22cliente%22%3A+%22Oscar+Blancarte%22%2C%0A++%22rfc%22%3A+%22BAIO8601201I5%22%0A%7D&r=%7B%0A++%22noPoliza%22%3A+%221234567890%22%2C%0A++%22total%22%3A+100000%2C%0A++%22iva%22%3A+2313%2C%0A++%22cliente%22%3A+%22Oscar+Blancarte%22%2C%0A++%22rfc%22%3A+%22BAIO8601201I5%22%0A%7D
import { Differ } from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import AppContext from '../../state/AppContext';
import WarningIcon from '@mui/icons-material/Warning';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { setLoading } from '../../template/app-actions';
import toast from 'react-hot-toast';
import Tour from 'reactour'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';
import { hasText } from '../../utils/StringUtils';
import { fromISOToLocal, fromISOToUTCFormat } from '../../utils/DateUtilsUTC';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';


const steps = [
    {
        selector: '[data-tut="first-step"]',
        content: 'In this section you will be able to see all the revisions or versions of the document and how it has changed over time',
    }, {
        selector: '[data-tut="second-step"]',
        content: 'From here you can compare one version with another to detect changes between two versions',
    }
]


const columns = [
    {
        field: 'collection',
        headerName: 'Collection',
        type: 'string',
        align: 'left'
    }, {
        field: 'documentKey',
        headerName: 'Key',
        type: 'string',
        align: 'left'
    }, {
        field: 'revisionNo',
        headerName: 'Revision',
        type: 'string',
        align: 'left'
    }, {
        field: 'rule',
        headerName: 'Rule',
        type: 'string',
        align: 'left'
    }, {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        align: 'left'
    }, {
        field: 'eventTime',
        headerName: 'Alert Time',
        type: 'string',
        align: 'left'
    }
]


const Document = (props) => {

    const [state, setState] = useState(undefined)
    const { collection, documentId } = useParams()
    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const [selectedRev, setSelectedRev] = useState(0)
    const location = useLocation();
    const [showTour, setShowTour] = useState(window.localStorage.getItem(`page-document.tour`) === null)

    const [alertData, setAlertData] = useState({
        queryBuilder: {
            queryString: `?document=${documentId}&collection=${collection}&sort=eventTime,desc`
        },
        pageSize: 10,
        totalItems: 0,
        currentPage: 0,
        totalPages: 0,
        items: []
    })


    useEffect(() => {
        try {
            setLoading(true)
            init()
        } catch (error) {
            toast.error("Unexpected error")
        } finally {
            setLoading(false)
        }

    }, [project, documentId])

    useEffect(() => {
        try {
            setLoading(true)
            fetchAlerts(alertData)
        } catch (error) {
            toast.error("Unexpected error")
        } finally {
            setLoading(false)
        }

    }, [state?.key, alertData.currentPage, alertData.pageSize])


    const init = async () => {
        const response = await (await APIInvoke.invokeGET(`/projects/${project}/collections/${collection}/documents/${documentId}`)).json()
        setState(response.body)
    }

    const fetchAlerts = async (newState) => {
        try {
            const query = newState.queryBuilder.queryString
            const response = await (await APIInvoke.invokeGET(`/projects/${project}/collections/alerts${newState.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${newState.currentPage}&sort=name,desc`)).json()
            if (response.ok) {
                setAlertData({
                    ...newState,
                    items: response.body.items,
                    totalItems: response.body.totalItems,
                    currentPage: response.body.currentPage,
                    totalPages: response.body.totalPages,
                    pageSize: response.body.pageSize
                });
            } else {
                toast.error(response.message)
            }
        } catch (error) {

        }
    }


    const handleListItemClick = (e, index) => {
        setSelectedRev(index);
    }

    const handleCloseTour = () => {
        setShowTour(false)
        window.localStorage.setItem("page-document.tour", "true")
    }

    const handleChangeAlertPage = (e, newPage) => {
        setAlertData({
            ...setAlertData,
            currentPage: newPage
        })
    }

    const parseJson = (payload) => {
        const jsonString = JSON.stringify(payload)
        const json = JSON.parse(jsonString, (key, value) => {
            const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;
            if (typeof value === 'string' && isoDateRegex.test(value)) {
                return fromISOToLocal(value);
            }
            return value;
        });
        return JSON.stringify(json, null, 2)
    }

    //if (state == null) return null

    return (

        <div className="">
            <Header title={<span>{state?.key}<span className="text-xs text-gray-700">/ {documentId} </span></span>} backLink={`/projects/${project}/collections/${collection}`} >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600" >
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Collections</span></Typography>
                    <Typography ><span className="text-gray-600">{collection}</span></Typography>
                    <Typography ><span className="text-gray-600">{documentId}</span></Typography>
                </Breadcrumbs>
            </Header>
            {state && (
                <div className="content grid gap-8">
                    <div className="grid gap-8">
                        <div className="grid gap-2 bg-gray-50 p-4 rounded-lg border border-gray-300">
                            <p className="font-bold">Metadata</p>
                            <div className="flex gap-1">
                                {
                                    Object.keys(state.metadata).map((m, index) => (
                                        <Chip key={index} label={`${m}=${state.metadata[m]}`} variant="filled" className="text-sm" size="medium" />
                                    ))
                                }
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <Box sx={{ width: '100%' }}>
                                <Paper sx={{ width: '100%', mb: 2 }}>
                                    <div className="bg-gray-300 text-dark-500 pl-4 py-3">
                                        <div className="flex justify-between items-center">
                                            <p >Revisions</p>
                                        </div>
                                    </div>
                                    <List data-tut="first-step" component="nav" className="rounded-md max-h-[500px] overflow-auto" >
                                        {state.revisions.map((rev, index) => (
                                            <ListItemButton
                                                className="border border-gray-300"
                                                key={rev.revision}
                                                selected={selectedRev === index}
                                                onClick={(event) => handleListItemClick(event, index)}

                                            >
                                                <ListItemText
                                                    className="text-dark-500"
                                                    primary={`Revision: ${rev.revision}`}
                                                    disablePadding
                                                    secondary={
                                                        <div >
                                                            <p className="text-dark-500 text-xs">By: {rev.createBy}</p>
                                                            <p className="text-dark-500 text-xs">Created At: {fromISOToUTCFormat(rev.createAt, 'dd/MM/yyyy HH:mm:ss')}</p>
                                                            <p className="text-dark-500 text-xs">Received At: {fromISOToUTCFormat(rev.receivedAt, 'dd/MM/yyyy HH:mm:ss')}</p>
                                                        </div>}
                                                />
                                                {!rev.valid && (
                                                    <ListItemAvatar>
                                                        <Tooltip title={rev.schemaErrors} >
                                                            <WarningIcon fontSize="small" className="text-red" />
                                                        </Tooltip>
                                                    </ListItemAvatar>
                                                )}
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Paper>
                            </Box>
                            <div className="col-span-2">
                                <Box sx={{ width: '100%' }}>
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <div className="bg-gray-300 text-dark-500 pl-4 py-1">
                                            <div className="flex justify-between items-center">
                                                <p >Revision: {state.revisions[selectedRev]?.revision}</p>
                                                <div>
                                                    <Tooltip title="Add documento to sandbox" >
                                                        <IconButton>
                                                            <MoveToInboxIcon className="text-dark-900" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip className="" title="Compare this document with other revisions" >
                                                        <IconButton
                                                            disabled={state.revisions.length <= 1}
                                                            color="success"
                                                            data-tut="second-step"
                                                            LinkComponent={Link}
                                                            to={{ pathname: `/projects/${project}/collections/${collection}/documents/${documentId}/diff`, state: { backLink: -1 } }}
                                                            state={location.state}  >
                                                            <CompareIcon className="text-dark-900" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <SyntaxHighlighter showLineNumbers={true} language="json" style={github} className="h-[500px] text-sm" >
                                            {parseJson(state.revisions[selectedRev].payload)}
                                        </SyntaxHighlighter>
                                    </Paper>
                                </Box>
                            </div>

                        </div>

                        <div className="grid gap-4">
                            <p className="text-2xl">Alerts</p>
                            <Box >
                                <Paper >
                                    <TableContainer>
                                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((headCell) => (
                                                        <TableCell key={headCell.field} align={headCell.align} padding={'normal'} >
                                                            <TableSortLabel >
                                                                {headCell.headerName}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {alertData.items.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell align="left">{row.collection}</TableCell>
                                                            <TableCell align="left">{row.documentKey}</TableCell>
                                                            <TableCell align="left">{row.revisionNo}</TableCell>
                                                            <TableCell align="left">{row.rule.name}</TableCell>
                                                            <TableCell align="left">{row.rule.description}</TableCell>
                                                            <TableCell align="left">{row.eventTime}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 50]}
                                        component="div"
                                        rowsPerPage={alertData.pageSize}
                                        count={alertData.totalItems}
                                        page={alertData.currentPage}
                                        onPageChange={handleChangeAlertPage}
                                        onRowsPerPageChange={() => { }}
                                    />
                                </Paper>
                            </Box>
                        </div>
                    </div>
                </div>
            )}

            <Tour steps={steps} isOpen={showTour} onRequestClose={handleCloseTour} closeWithMask={false} />
        </div>
    )
}

export default Document