import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { changeExpirationRange, checkPermissions, checkRole, init, reset, save, updateGenerales } from './edit-token-actions';
import { Box, Paper, TextField, Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Alert from '@mui/material/Alert';
import AppContext from '../../state/AppContext';
import GrantContent from '../../template/GrantContent';
import * as Permissions from '../../utils/Permissions'


const EditToken = (props) => {

    const state = useSelector(state => state.pages.editToken)
    const { appContext } = useContext(AppContext)
    const project = appContext.project

    useEffect(() => {
        init()
        return () => reset()
    }, [project])

    return (
        <div className="content">
            <div className="grid gap-8">
                <div className="layout grid gap-4">
                    <Typography variant="h4" component="h4">
                        New Token
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb" separator="/">
                        <Link underline="hover" className="text-gray-900" to="/">Home</Link>
                        <GrantContent permisos={[Permissions.PROJECTS_W]}>
                            {(grant) => {
                                return grant
                                    ? <Link underline="hover" className="text-gray-900" to={`/projects`}>Projects</Link>
                                    : <Typography ><span className="text-gray-600">Projects</span></Typography>
                            }}
                        </GrantContent>
                        <GrantContent permisos={[Permissions.PROJECTS_W]}>
                            {(grant) => {
                                return grant
                                    ? <Link underline="hover" className="text-gray-900" to={`/projects/${project}/edit`}>{project}</Link>
                                    : <Typography ><span className="text-gray-600">{project}</span></Typography>
                            }}
                        </GrantContent>
                        <Typography ><span className="text-gray-600">New Token</span></Typography>
                    </Breadcrumbs>
                </div>
                {state.token && (
                    <div className="grid">
                        <Alert severity="success">
                            <small className="block mb-2">Please keep the token in a safe place as you will be able to see it again once you leave this section.</small>
                            <p className="break-all">{state.token}</p>
                        </Alert>
                    </div>
                )}
                {!state.token && (
                    <div >
                        <form onSubmit={e => save(e, project)}  >
                            <div className="grid gap-8">
                                <div className="grid grid-cols-2 gap-8">
                                    <Box>
                                        <Paper className="p-4">
                                            <div className="grid gap-4">
                                                <TextField
                                                    required
                                                    disabled={!state.new}
                                                    size="medium"
                                                    label="Name"
                                                    name="name"
                                                    onChange={updateGenerales}
                                                    value={state.name}
                                                />
                                                <div className="grid grid-cols-2 gap-4">
                                                    <FormControl required>
                                                        <InputLabel id="expirationOpt" >Expiration</InputLabel>
                                                        <Select name="expirationOpt" labelId="expirationOpt" required onChange={changeExpirationRange} value={state.expirationOpt}>
                                                            <MenuItem disabled value=""><em>Select</em></MenuItem>
                                                            <MenuItem value="6">7 days</MenuItem>
                                                            <MenuItem value="29">30 days</MenuItem>
                                                            <MenuItem value="59">60 days</MenuItem>
                                                            <MenuItem value="89">90 days</MenuItem>
                                                            <MenuItem value="0">Custom</MenuItem>
                                                            <MenuItem value="-1">No expiration</MenuItem>
                                                        </Select>
                                                        <FormHelperText>Expire on:
                                                            {state.expirationOpt === -1
                                                                ? "Never"
                                                                : state.expirationOpt === "" ? "" : state.expiration.toFormat("yyyy/MM/dd")
                                                            }
                                                        </FormHelperText>
                                                    </FormControl>
                                                    {state.expirationOpt === 0 && (
                                                        <FormControl variant="outlined" size="small" required fullWidth={true} >
                                                            <LocalizationProvider dateAdapter={AdapterLuxon}    >
                                                                <DatePicker
                                                                    name="expiration"
                                                                    format="yyyy-MM-dd"
                                                                    views={['day', 'month', 'year']}
                                                                    label="Expiration"
                                                                    value={state.expiration}
                                                                    minDate={DateTime.now()}
                                                                    onChange={(newValue, dateAsString) => updateGenerales({ target: { name: "expiration", value: newValue } })}
                                                                    onAccept={(newValue) => updateGenerales({ target: { name: "expiration", value: newValue } })}
                                                                    inputFormat="dd/MM/yyyy"
                                                                />
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                    )}
                                                </div>
                                            </div>
                                        </Paper>
                                    </Box>
                                </div>
                                <div className="grid grid-cols-2 gap-8">
                                    <Box >
                                        <Paper className="p-4">
                                            <div className="grid gap-2">
                                                <p>Roles</p>
                                                {state.roles.map((rol, index) => (
                                                    <FormControl key={rol.name}>
                                                        <FormControlLabel
                                                            disabled={state.master}
                                                            control={<Checkbox />}
                                                            checked={rol.selected}
                                                            label={rol.name}
                                                            onClick={e => checkRole(e, index)}
                                                            labelPlacement="end"
                                                        />
                                                        <FormHelperText>{rol.descripcion}</FormHelperText>
                                                    </FormControl>
                                                ))}
                                            </div>
                                        </Paper>
                                    </Box>
                                    <Box >
                                        <Paper className="p-4">
                                            <div className="grid gap-1">
                                                <p>Permissions</p>
                                                {state.permissions.map((permission, index) => (
                                                    <FormControl key={permission.name} >
                                                        <FormControlLabel
                                                            value="end"
                                                            labelPlacement="end"
                                                            disabled={state.master}
                                                            control={<Checkbox />}
                                                            checked={permission.selected}
                                                            label={permission.name}
                                                            onClick={e => checkPermissions(e, index)}
                                                        />
                                                        <FormHelperText>{permission.descripcion}</FormHelperText>
                                                    </FormControl>
                                                ))}
                                            </div>
                                        </Paper>
                                    </Box>
                                </div>
                                <div className="text-right">
                                    <Button disabled={state.token} variant="contained" type="submit" className="text-dark-500 py-10" >CREATE TOKEN</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    )
}

export default EditToken