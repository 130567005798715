import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import AppContext from '../../state/AppContext';
import { Breadcrumbs, Typography, Box, Paper, Tabs, Tab, TextField, Button } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source'
import KeyIcon from '@mui/icons-material/Key'
import UserContext from '../../state/UserContext';
import APIInvoke from '../../utils/APIInvoke';
import { toast } from 'react-hot-toast';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';


const Profile = (props) => {

    const { appContext } = useContext(AppContext)
    const { user } = useContext(UserContext)
    const [tab, setTab] = useState(0)
    const [state, setState] = useState({
        oldPassword: "",
        newPassword: "",
        newPassword2: ""
    })
    const project = appContext.project

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const changePassword = async (e) => {
        e.preventDefault()

        if (state.newPassword !== state.newPassword2) {
            toast.error("The two passwords do not match")
            return
        }

        const request = {
            oldPassword: state.oldPassword,
            newPassword: state.newPassword
        }
        const response = await (await APIInvoke.invokePOST(`/users/password`, request)).json()
        if (response.ok) {
            toast.success(response.message)
            setState({
                oldPassword: "",
                newPassword: "",
                newPassword2: ""
            })
        } else {
            toast.error(response.message)
        }
    }

    return (
        <div className="">
            <Header title="Collections" >
                <Breadcrumbs aria-label="breadcrumb" separator="/">
                    <Link underline="hover" className="text-gray-900 flex " to="/"><HomeOutlinedIcon className="text-dark-500" fontSize="small" /></Link>
                    <Typography ><span className="text-gray-600">My profile</span></Typography>
                </Breadcrumbs>
            </Header>
            <div className="content grid gap-8">
                <div className="grid gap-8" >
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
                                        <Tab label="General" icon={<SourceIcon />} iconPosition="start" />
                                        <Tab label="Password" icon={<KeyIcon />} iconPosition="start" />
                                    </Tabs>
                                </Box>
                            </Box>
                            {tab === 0 && (
                                <div className="grid grid-cols-1 gap-4 p-4">
                                    <TextField
                                        disabled
                                        className="w-96"
                                        size="medium"
                                        name="name"
                                        label="name"
                                        value={user.username}
                                    />
                                    <TextField
                                        disabled
                                        className="w-96"
                                        size="medium"
                                        name="name"
                                        label="name"
                                        value={user.email}
                                    />
                                    <TextField
                                        disabled
                                        className="w-96"
                                        size="medium"
                                        name="createAt"
                                        label="Create At"
                                        value={user.createAt}
                                    />
                                </div>
                            )}

                            {tab === 1 && (
                                <form onSubmit={changePassword}>
                                    <div className="grid grid-cols-1 gap-4 p-4">
                                        <TextField
                                            required
                                            className="w-96"
                                            size="medium"
                                            name="oldPassword"
                                            label="Current Password"
                                            type="password"
                                            value={state.oldPassword}
                                            onChange={onChange}
                                        />
                                        <TextField
                                            required
                                            className="w-96"
                                            size="medium"
                                            name="newPassword"
                                            label="New Password"
                                            type="password"
                                            value={state.newPassword}
                                            onChange={onChange}
                                        />
                                        <TextField
                                            required
                                            className="w-96"
                                            size="medium"
                                            name="newPassword2"
                                            label="Repeat New Password"
                                            type="password"
                                            value={state.newPassword2}
                                            onChange={onChange}
                                        />
                                        <Button type="submit" variant="contained" className="text-dark-500 w-96" >CHANGE PASSWORD</Button>
                                    </div>
                                </form>
                            )}
                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Profile