import { Box, Breadcrumbs, Button, Checkbox, FormControlLabel, IconButton, LinearProgress, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { Link, useNavigate, useParams } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../template/ConfirmDialog';
import { toast } from 'react-hot-toast';
import AppContext from '../../state/AppContext';
import { setLoading } from '../../template/app-actions';
import * as Permissions from '../../utils/Permissions'
import GrantContent from '../../template/GrantContent';
import FilterBuilder from '../../components/FilterBuilder/FilterBuilder';
import TextFilter from '../../components/FilterBuilder/controls/TextFilter';
import { hasText } from '../../utils/StringUtils';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';
import { useSelector } from 'react-redux';

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        align: 'left'
    }, {
        field: 'id',
        headerName: 'ID',
        type: 'string',
        align: 'left'
    }, {
        field: 'skipUnchangedDocuments',
        headerName: 'Skip unchaged',
        type: 'string',
        align: 'left'
    }, {
        field: 'useSchema',
        headerName: 'Use Schema',
        type: 'string',
        align: 'left'
    }, {
        field: '',
        headerName: 'Actions',
        type: 'string',
        align: 'right'
    }
]

const filters = [
    {
        id: "name",
        name: "Name",
        placeholder: "Name",
        filter: "name[cn]",
        type: TextFilter,
        defaultValue: ""
    }
]

const initialState = {
    queryBuilder: {
        queryString: `?sort=name,asc`
    },
    pageSize: 10,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    items: []
}

const Collections = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState(initialState)
    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const { loading } = useSelector(state => state.pages.app)
    const [showDeleteDialog, setShowDeleteDialog] = useState({ open: false, payload: null })

    useEffect(() => {
        try {
            setLoading(true)
            init(data)
        } catch (error) {
            console.error("Projects.useEffect => ", error)
        } finally {
            setLoading(false)
        }
    }, [project, data.currentPage, data.pageSize])

    const init = async (newState) => {
        try {
            setLoading(true)
            const query = newState.queryBuilder.queryString
            const response = await (await APIInvoke.invokeGET(`/projects/${project}/collections${newState.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${newState.currentPage}&size=${newState.pageSize || 10}&sort=name,desc`)).json()
            if (response.ok) {
                setData({
                    ...newState,
                    items: response.body.items,
                    totalItems: response.body.totalItems,
                    currentPage: response.body.currentPage,
                    totalPages: response.body.totalPages,
                    pageSize: response.body.pageSize
                });
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.error("Error lo load page", error)
        } finally {
            setLoading(false)
        }
    }

    const onFilterProcess = async (queryBuilder) => {
        const newState = {
            ...data,
            queryBuilder: queryBuilder,
            totalItems: 0,
            currentPage: 0,
            totalPages: 0,
            pageSize: 10,
        }
        setData(newState)
        init(newState)
    }


    const onRowClick = (event, row) => {
        navigate(`/projects/${project}/collections/${row.name}`, { state: { backLink: -1 } })
    }

    const onEditClick = (event, row) => {
        event.stopPropagation()
        navigate(`/projects/${project}/collections/${row.name}/edit`, { state: { backLink: -1 } })
    }

    const onDelete = async () => {
        const response = await (await APIInvoke.invokeDELETE(`/projects/${project}/collections/${showDeleteDialog.payload.name}`)).json()
        if (response.ok) {
            toast.success(response.message)
            setData({
                ...data,
                items: data.items.filter(x => x.name !== showDeleteDialog.payload.name)
            })
            setShowDeleteDialog({ open: false, payload: null })
        } else {
            toast.error(response.message)
        }
    }

    const toggleDeleteDialog = (event, payload) => {
        event.stopPropagation()
        setShowDeleteDialog({
            open: !showDeleteDialog.open,
            payload
        })
    }

    const handleChangePage = (e, newPage) => {
        setData({
            ...data,
            currentPage: newPage
        })
    }

    const onRowsPerPageChange = (event) => {
        setData({
            ...initialState,
            items: data.items,
            pageSize: parseInt(event.target.value, 10)
        })
    }

    return (
        <div className="">
            <ConfirmDialog title="Delete collection" message="This action is permanent and there will be no way to recover the collection" open={showDeleteDialog.open} onClose={toggleDeleteDialog} onConfirm={onDelete} />
            <Header title="Collections" >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600" >
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Collections</span></Typography>
                </Breadcrumbs>
            </Header>

            <div className="content ">
                <div className="grid gap-8">

                    <div className="text-right">
                        <GrantContent permisos={[Permissions.COLLECTIONS_W]}>
                            <Button variant="contained" LinkComponent={Link} to={`/projects/${project}/collections/_new/edit`} state={{ backLink: -1 }}><AddIcon /> NEW COLLECTION</Button>
                        </GrantContent>
                    </div>
                    <div >
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <div>
                                    <FilterBuilder filters={filters} onProcess={onFilterProcess} />
                                </div>
                                {loading && <LinearProgress size="sm" />}
                                <TableContainer>
                                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((headCell) => (
                                                    <TableCell key={headCell.field} align={headCell.align} padding={'normal'} >
                                                        <TableSortLabel >
                                                            {headCell.headerName}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.items.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => onRowClick(event, row)}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell align="left">{row.name}</TableCell>
                                                        <TableCell align="left">{row.id}</TableCell>
                                                        <TableCell align="left">
                                                            <Checkbox disabled checked={row.skipUnchangedDocuments === true} />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Checkbox disabled checked={row.schema?.validate === true} />
                                                        </TableCell>
                                                        <TableCell width={130} align="right">
                                                            <GrantContent permisos={[Permissions.COLLECTIONS_W]}>
                                                                <Tooltip title="Edit">
                                                                    <IconButton onClick={(event) => onEditClick(event, row)} ><EditNoteIcon className="hover:text-green" /></IconButton>
                                                                </Tooltip>
                                                            </GrantContent>

                                                            <GrantContent permisos={[Permissions.COLLECTIONS_D]}>
                                                                <Tooltip title="Delete">
                                                                    <IconButton onClick={(event) => toggleDeleteDialog(event, row)} ><DeleteIcon className="hover:text-red" /></IconButton>
                                                                </Tooltip>
                                                            </GrantContent>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {loading && <LinearProgress size="sm" />}
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    component="div"
                                    rowsPerPage={data.pageSize}
                                    count={data.totalItems}
                                    page={data.currentPage}
                                    disabled={loading}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </Paper>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Collections 