import { createTheme } from "@mui/material";

// Create a theme instance.
const theme = createTheme({
    
    palette: {
        primary: {
            main: '#2ecc71',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#c20083',
        },
        error: {
            main: "#FF2020",
        },
        background: {
            default: '#fff',
        },
    },
});

export default theme;