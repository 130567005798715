import React, { useContext } from 'react'
import Aside from './Aside';
import Main from './Main';
import UserContext from '../state/UserContext';

const Template = (props) => {

    const { user } = useContext(UserContext)

    if (!user.username) {
        window.location = "/login"
        return null;
    }

    return (
        <div id="template">
            <Aside />
            <Main />
        </div>
    )
}

export default Template;