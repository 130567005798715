import { Box, Breadcrumbs, Button, Checkbox, FormControlLabel, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { Link, useNavigate } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-hot-toast';
import ConfirmDialog from '../../template/ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import GrantContent from '../../template/GrantContent';
import * as Permissions from '../../utils/Permissions'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        type: 'string',
    }, {
        field: 'id',
        headerName: 'ID',
        type: 'string',
    }, {
        field: '',
        headerName: 'Actions',
        type: 'string',
        align: 'right'
    }
]

const Projects = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState({
        pageSize: 10,
        totalItems: 0,
        currentPage: 0,
        items: []
    })
    const [loading, setLoading] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState({ open: false, payload: null })

    useEffect(() => {
        try {
            setLoading(true)
            init(data)
        } catch (error) {
            console.log("Projects.useEffect => ", error)
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        init(data)
    }, [data.currentPage, data.pageSize])

    const init = async (newState) => {
        const response = await (await APIInvoke.invokeGET(`/projects?page=${newState.currentPage}&size=10`)).json()
        setData({
            ...response.body,
            items: response.body.items,
            totalItems: response.body.totalItems,
            currentPage: response.body.currentPage,
            totalPages: response.body.totalPages,
            pageSize: response.body.pageSize
        })
    }

    const handleChangePage = (e, newPage) => {
        setData({
            ...data,
            currentPage: newPage
        })
    }

    const onDelete = async () => {
        const response = await (await APIInvoke.invokeDELETE(`/projects/${showDeleteDialog.payload.name}`)).json()
        if (response.ok) {
            toast.success(response.message)
            setData({
                ...data,
                items: data.items.filter(x => x.name !== showDeleteDialog.payload.name)
            })
            setShowDeleteDialog({ open: false, payload: null })
        } else {
            toast.error(response.message)
        }
    }

    const toggleDeleteDialog = (event, payload) => {
        event.stopPropagation()
        setShowDeleteDialog({
            open: !showDeleteDialog.open,
            payload
        })
    }

    return (
        <div className="">
            <ConfirmDialog title="Delete project" message="This action is permanent and there will be no way to recover the project" open={showDeleteDialog.open} onClose={toggleDeleteDialog} onConfirm={onDelete} />
            <Header title="Projects" >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Projects</span></Typography>
                </Breadcrumbs>
            </Header>
            <div className="content grid gap-8">
                <div className="text-right">
                    <GrantContent permisos={[Permissions.PROJECTS_W]}>
                        <Button variant="contained" LinkComponent={Link} to={`/projects/_new/edit`} state={{ backLink: -1 }}><AddIcon /> NEW PROJECT</Button>
                    </GrantContent>
                </div>
                <div >
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((headCell) => (
                                                <TableCell key={headCell.field} align={'left'} padding={'normal'} >
                                                    <TableSortLabel >
                                                        {headCell.headerName}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.items.map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    //onClick={(event) => onRowClick(event, row)}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell align="left">{row.id}</TableCell>
                                                    <TableCell width={130} align="right">
                                                        <GrantContent permisos={[Permissions.PROJECTS_W]}>
                                                            <Tooltip title="Edit">
                                                                <IconButton onClick={() => navigate(`/projects/${row.name}/edit`, { state: { backLink: -1 } })} ><EditNoteIcon className="hover:text-green" /></IconButton>
                                                            </Tooltip>
                                                        </GrantContent>
                                                        <GrantContent permisos={[Permissions.PROJECTS_D]}>
                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={(event) => toggleDeleteDialog(event, row)} ><DeleteIcon className="hover:text-red" /></IconButton>
                                                            </Tooltip>
                                                        </GrantContent>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                rowsPerPage={data.pageSize}
                                count={data.totalItems}
                                page={data.currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={() => { }}
                            />
                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Projects 