import { useContext } from 'react'
import UserContext from '../state/UserContext'

export default function GrantContent(props) {

    const { roles, permisos, children } = props
    const { user } = useContext(UserContext)
    const userRoles = user?.roles
    const userPermisos = user?.permissions

    const canAccess = () => {
        if (permisos) {
            for (let i = 0; i < permisos.length; i++) {
                if (userPermisos.includes(permisos[i])) return true
            }
        }

        if (roles) {
            for (let i = 0; i < roles.length; i++) {
                if (userRoles.includes(roles[i])) return true
            }
        }

        return false
    }

    if (!user) {
        return null
    }

    if (userRoles.includes("ADMIN") || canAccess()) {
        return typeof children === 'function' ? children(true) : children
    } else {
        return typeof children === 'function' ? children(false) : null
    }
}