import store from '../../redux/store'
import {
    EDIT_COLLECTIONS_RESET,
    EDIT_COLLECTIONS_GENERALES,
    EDIT_COLLECTIONS_RULES_CONFIG,
    EDIT_COLLECTIONS_METADATA_MAPPER
} from '../../redux/redux-const'
import APIInvoke from '../../utils/APIInvoke';
import toast from 'react-hot-toast';
import { hasText } from '../../utils/StringUtils';


const getState = () => {
    return store.getState().pages.editCollection
}

const merge = (type, param) => {
    store.dispatch({
        type,
        payload: {
            ...getState(),
            ...param
        }
    })
}

export const reset = () => {
    store.dispatch({ type: EDIT_COLLECTIONS_RESET })
}

export const init = async (project, collection) => {
    const response = await (await APIInvoke.invokeGET(`/projects/${project}/collections/${collection}`)).json()
    let stringSchema = ""
    try {
        stringSchema = JSON.stringify(JSON.parse(response.body.schema?.schema), null, 2)
    } catch (error) {
        stringSchema = response.body.schema?.schema
    }

    merge(EDIT_COLLECTIONS_GENERALES, {
        new: false,
        id: response.body.id,
        name: response.body.name,
        useSchema: hasText(response.body.schema),
        skipUnchangedDocuments: response.body.skipUnchangedDocuments,

        useSchema: response.body.schema?.validate || false,
        rejectInvalid: response.body.schema?.rejectInvalid || false,
        schemaType: response.body.schema?.schemaType || "JSON",
        schema: stringSchema,
        rulesConfig: {
            rulesExample: response.body.rulesConfig?.rulesExample || "",
            rulesPreviewExample: response.body.rulesConfig?.rulesPreviewExample || "",
            rules: response.body.rulesConfig?.rules.map(rule => ({
                id: rule.id,
                enable: rule.enable,
                name: rule.name,
                description: rule.description,
                expression: rule.expression
            })) || [],
            notifyTo: response.body.rulesConfig?.notifyTo || []
        },
        metadataMapper: {
            enabled: response.body.metadataMapper?.enabled,
            mapperMode: response.body.metadataMapper?.mapperMode,
            documentExample: response.body.metadataMapper?.documentExample,
            expression: response.body.metadataMapper?.expression
        }
    })
}

export const updateGenerales = (action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value


    if (type === "checkbox") {
        value = action.target.checked
    }

    console.log(name, type, value)
    merge(EDIT_COLLECTIONS_GENERALES, { [name]: value })
}


export const save = async (project, navigate) => {
    const state = getState()
    const request = {
        name: state.name,
        skipUnchangedDocuments: state.skipUnchangedDocuments,
        schema: {
            validate: state.useSchema,
            rejectInvalid: state.rejectInvalid,
            schemaType: state.schemaType,
            schema: state.schema?.replace(/\n/g, "")
        },
        rulesConfig: {
            rulesExample: state.rulesConfig.rulesExample,
            rulesPreviewExample: state.rulesConfig.rulesPreviewExample,
            notifyTo: state.rulesConfig.notifyTo,
            rules: state.rulesConfig.rules.map(rule => ({
                id: rule.id,
                enable: rule.enable,
                name: rule.name,
                description: rule.description,
                expression: rule.expression
            }))
        },
        metadataMapper: {
            enabled: state.metadataMapper.enabled,
            mapperMode: state.metadataMapper.mapperMode,
            documentExample: state.metadataMapper.documentExample,
            expression: state.metadataMapper.expression
        }
    }
    const response = state.id
        ? await (await APIInvoke.invokePUT(`/projects/${project}/collections/${state.name}`, request)).json()
        : await (await APIInvoke.invokePOST(`/projects/${project}/collections`, request)).json()
    if (response.ok) {
        merge(EDIT_COLLECTIONS_GENERALES, {
            new: false,
            id: response.body.id,
            name: response.body.name,
            skipUnchangedDocuments: response.body.skipUnchangedDocuments,
            rulesConfig: response.body.rulesConfig
        })
        toast.success(response.message)
        navigate(`/projects/${project}/collections/${state.name}/edit`)
    } else {
        toast.error(response.message)
    }
}


export const updateRulesConfig = (action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value


    if (type === "checkbox") {
        value = action.target.checked
    }

    const state = getState();

    store.dispatch({
        type: EDIT_COLLECTIONS_RULES_CONFIG,
        payload: {
            ...state.rulesConfig,
            [name]: value
        }
    })
}

export const updateMetadataMapper = (action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value


    if (type === "checkbox") {
        value = action.target.checked
    }

    const state = getState();

    store.dispatch({
        type: EDIT_COLLECTIONS_METADATA_MAPPER,
        payload: {
            ...state.metadataMapper,
            [name]: value
        }
    })
}


export const updateRule = (rule, action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value


    if (type === "checkbox") {
        value = action.target.checked
    } else if (type === "date") {
    }


    const state = getState();
    const rules = state.rulesConfig.rules;

    const index = rules.findIndex(rul => rul.id === rule.id)

    if (name === "operator") {
        const type = rule.datatype
        const operator = type.operators.find(op => op.operator === value)
        rule.dataoperator = operator;
    }

    if (name === "type") {
        const type = state.rulesConfig.types.find(type => type.type === value)
        rules[index] = Object.assign({}, rule, { [name]: value, value: "", datatype: type })
    } else {
        rules[index] = Object.assign({}, rule, { [name]: value })
    }

    store.dispatch({
        type: EDIT_COLLECTIONS_RULES_CONFIG,
        payload: {
            ...state.rulesConfig,
            rules: [...rules]
        }
    })
}

export const addRule = () => {
    const state = getState();
    const rules = state.rulesConfig.rules;


    store.dispatch({
        type: EDIT_COLLECTIONS_RULES_CONFIG,
        payload: {
            ...state.rulesConfig,
            rules: [
                ...rules,
                {
                    enable: true,
                    name: "",
                    description: "",
                    expression: ""
                }
            ]
        }
    })
}

function parseJson(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
}

export const testRule = async (rule, project, collection) => {
    const state = getState();
    const rules = state.rulesConfig.rules;

    const request = {
        expression: rule.expression,
        rulesExample: parseJson(state.rulesConfig.rulesExample),
        rulesPreviewExample: parseJson(state.rulesConfig.rulesPreviewExample)
    }

    const response = await (await APIInvoke.invokePOST(`/projects/${project}/collections/rules/expression/test`, request)).json()

    const index = rules.findIndex(rul => rul.id === rule.id)

    rules[index] = Object.assign({}, rule, {
        testResult: response.body
    })

    store.dispatch({
        type: EDIT_COLLECTIONS_RULES_CONFIG,
        payload: {
            ...state.rulesConfig,
            rules: [...rules]
        }
    })

}


// 
export const testMetadataMapping = async (project) => {
    const state = getState();

    const request = {
        expression: state.metadataMapper.expression,
        documentExample: parseJson(state.metadataMapper.documentExample),
    }

    const response = await (await APIInvoke.invokePOST(`/projects/${project}/collections/metadata/expression/test`, request)).json()

    store.dispatch({
        type: EDIT_COLLECTIONS_METADATA_MAPPER,
        payload: {
            ...state.metadataMapper,
            result: response.body
        }
    })
}


export const addNotifyTo = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const state = getState();

    const value = event.target.value;

    store.dispatch({
        type: EDIT_COLLECTIONS_RULES_CONFIG,
        payload: {
            ...state.rulesConfig,
            notifyTo: [
                ...state.rulesConfig.notifyTo,
                value
            ]
        }
    })

    event.target.value = "";
}

export const removeNotifyTo = (user) => {
    const state = getState();
    store.dispatch({
        type: EDIT_COLLECTIONS_RULES_CONFIG,
        payload: {
            ...state.rulesConfig,
            notifyTo: state.rulesConfig.notifyTo.filter(x => x !== user)
        }
    })
}

export const deleteRule = (rule) => {
    const state = getState();
    store.dispatch({
        type: EDIT_COLLECTIONS_RULES_CONFIG,
        payload: {
            ...state.rulesConfig,
            rules: state.rulesConfig.rules.filter(x => x !== rule)
        }
    })
}