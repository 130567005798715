import { DateTime } from "luxon";
import { COLLECTION_SET_STATE, COLLECTION_CLEAN_FILTERS } from "../redux-const";
import { keyOperators } from "../../components/QueryBuilder/QueryBuilder";

export const initialState = {
    loading: undefined,
    queryBuilder: {
        combinator: 'and',
        rules: [
            {
                field: 'lastReceivedAt',
                operator: '>=',
                value: DateTime.now().minus({ days: 1 }).startOf("day").toFormat("yyyy-MM-dd'T'HH:mm") // "2024-10-30T12:00"
            },
        ],
    },
    query: undefined,
    fields: [
        { name: 'key', label: 'Key', inputType: "string", operators: keyOperators, defaultOperator: "=" },
    ],
    showDeleteDialog: {
        open: false,
        payload: null
    },
    pageSize: 10,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
}


export const reducer = (state = initialState, action) => {
    const payload = action.payload;


    switch (action.type) {
        case COLLECTION_SET_STATE:
            return { ...state, ...payload } 
        case COLLECTION_CLEAN_FILTERS:
            return { ...initialState }
        default:
            return state
    }
};

export default reducer;
