import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";

// Reducers
import editCollection from "./reducers/edit-collection-store";
import editProject from "./reducers/edit-project-store";
import editUser from "./reducers/edit-user-store";
import editToken from "./reducers/edit-token-store";
import editAppMonitoring from "./reducers/edit-app-monitor-store"
import app from "./reducers/app-store"
import collection from "./reducers/collection-store"
const middleware = [];
if (process.env.NODE_ENV !== "production") {
  //middleware.push(createLogger());
}

const pages = combineReducers({
  app,
  collection,
  editCollection,
  editProject,
  editUser,
  editToken,
  editAppMonitoring
});

const reducers = combineReducers({
  pages,
});

const store = createStore(
  reducers,
  composeWithDevTools({
    name: `goback.dev`,
    hostname: 'localhost',
    port: 4000,
  })(applyMiddleware(...middleware))
);

export default store;
