import { Box, Breadcrumbs, Button, Paper, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { Link, useNavigate, useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-hot-toast';
import AppContext from '../../state/AppContext';
import { setLoading } from '../../template/app-actions';
import * as Permissions from '../../utils/Permissions'
import GrantContent from '../../template/GrantContent';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';
import Dagre from '@dagrejs/dagre';


import { Background, Controls, Handle, Position, MarkerType, Panel, ReactFlow, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import TLogNode from './TLogNode';

const nodeTypes = {
    tlog: TLogNode,
};

const proOptions = { hideAttribution: true };

const getLayoutedElements = (nodes, edges, options) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: options.direction });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) =>
        g.setNode(node.id, {
            ...node,
            width: node.measured?.width ?? 0,
            height: node.measured?.height ?? 0,
        }),
    );

    Dagre.layout(g);

    return {
        nodes: nodes.map((node) => {
            const position = g.node(node.id);
            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            const x = position.x - (node.measured?.width ?? 0) / 2;
            const y = position.y - (node.measured?.height ?? 0) / 2;

            return { ...node, position: { x, y } };
        }),
        edges,
    };
};

const TLog = (props) => {

    //const { fitView } = useReactFlow();
    const [state, setState] = useState([])
    const [connects, setConnects] = useState([])
    const { appContext } = useContext(AppContext)
    const { traceId } = useParams()
    const project = appContext.project


    const [nodes, setNodes, onNodesChange] = useNodesState(state);
    const [edges, setEdges, onEdgesChange] = useEdgesState(connects);

    useEffect(() => {
        try {
            setLoading(true)
            init(state)
        } catch (error) {
            console.log("Projects.useEffect => ", error)
        } finally {
            setLoading(false)
        }
    }, [project, traceId])

    const onLayout = (direction) => {
        const layouted = getLayoutedElements(state, connects, { direction });
        setState([...layouted.nodes]);
        setConnects([...layouted.edges]);

        window.requestAnimationFrame(() => {
            //fitView();
        });
    }

    const init = async (newState) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/projects/${project}/tlogs?traceId=${traceId}`)).json()
            if (response.ok) {
                const items = response.body.items;


                let nodes = [];

                for (let index = 0; index < items.length; index++) {
                    const log = items[index]
                    nodes.push({
                        id: log.id,
                        type: "tlog",
                        sourcePosition: 'right',
                        targetPosition: 'left',
                        position: {
                            x: (index + 1) * 200,
                            y: 50
                        },
                        data: { label: log.destination, payload: log }
                    })

                    const origin = log.origin
                    if (nodes.findIndex(x => x.id === origin) === -1) {
                        nodes.push({
                            id: origin,
                            sourcePosition: 'right',
                            targetPosition: 'left',
                            measured: {
                                width: 200,
                                height: 100
                            },
                            position: {
                                x: (index + 1) * 200,
                                y: 100
                            },
                            data: { label: origin }
                        })
                    }
                }

                //setState(nodes)

                const newConnects = items.map((log, index) => ({
                    id: `${log.origin}$${log.destination}`,
                    source: log.origin,
                    target: log.id,
                    label: `${log.responseCode}`,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                        color: log.responseCode === "200" ? '#2ecc71' : "red",
                    },
                    style: {
                        strokeWidth: 1,
                        stroke: log.responseCode === "200" ? '#2ecc71' : "red",
                    },
                    animated: true
                }))
                //setConnects(newConnects)
                const layouted = getLayoutedElements(nodes, newConnects, { direction: 'LR' });
                setState([...layouted.nodes]);
                setConnects([...layouted.edges]);


            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.error("Error lo load page", error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="">
            <Header title="Transactional Log" >
                <Breadcrumbs aria-label="breadcrumb" separator="/">
                    <Link underline="hover" className="text-gray-900 flex " to="/"><HomeOutlinedIcon className="text-dark-500" fontSize="small" /></Link>
                    <Typography ><span className="text-gray-600">Transactional Logs</span></Typography>
                </Breadcrumbs>
            </Header>

            <div className="content ">
                <div className="grid gap-8">
                    <div >
                        <Box className="w-full h-[500px]">
                            <Paper className="w-full h-[500px]">
                                <ReactFlow
                                    proOptions={proOptions}
                                    fitView
                                    nodes={state}
                                    edges={connects}
                                    nodeTypes={nodeTypes}
                                >
                                    {/*
                                    <Panel position="top-right">
                                        <div className="grid grid-cols-2 gap-4">
                                            <Button type="button" variant="contained" onClick={() => onLayout('TB')}>vertical layout</Button>
                                            <Button type="button" variant="contained" onClick={() => onLayout('LR')}>horizontal layout</Button>
                                        </div>
                                    </Panel>
                                    */}
                                    <Controls />
                                    <Background />
                                </ReactFlow>
                            </Paper>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TLog



