import {
    APP_SET_LOADING
} from "../redux-const";

const initialState = {
    loading: false
}

export const reducer = (state = initialState, action) => {
    const payload = action.payload;

    switch (action.type) {
        case APP_SET_LOADING:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
};

export default reducer;
