import { Box, Breadcrumbs, Button, Paper, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, IconButton, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SourceIcon from '@mui/icons-material/Source';
import { useSelector } from 'react-redux';
import { reset, init, save, updateGenerales, toggleDeleteTokenDialog, onDeleteToken, deleteProject, deleteAppMonitor } from './edit-app-monitor-actions';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../template/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import KeyIcon from '@mui/icons-material/Key';
import AppContext from '../../state/AppContext';
import { createTextKey } from '../../utils/StringUtils';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';
import GrantContent from '../../template/GrantContent';
import * as Permissions from '../../utils/Permissions'
import EditAppMonitorGenerals from './EditAppMonitorGenerals';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';


const columns = [
    {
        field: 'name',
        headerName: 'Name'
    }, {
        field: 'expiration',
        headerName: 'Expiration'
    }, {
        field: '',
        headerName: 'Actions',
        type: 'string'
    }
]


const EditAppMonitor = (props) => {

    const state = useSelector(state => state.pages.editAppMonitoring)
    const { appContext } = useContext(AppContext)
    const { project, appMonitorId } = useParams()
    const [tab, setTab] = useState(0)
    const navigate = useNavigate()



    const form = useForm({
        defaultValues: {
            type: "",
            name: "",
            method: "GET",
            pingInterval: 10,
            maxFailedPings: 3,
            excepctedResponseCode: 200,
            headers: [{
                name: "Content-Type",
                value: "application/json"
            }]
        }
    });

    const { register, handleSubmit, watch, formState: { errors }, control } = form;

    const onSubmit = (data) => {
        //save(project, navigate)
        console.log(data);
    }
    console.log(watch("type"));



    useEffect(() => {
        if (appMonitorId !== "_new") {
            init(project, appMonitorId)
        }

        return () => reset()
    }, [project, appMonitorId])

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div className="">
            <Header title={<span>{state.name}{state.new ? "*" : ""}</span>}>
                <Breadcrumbs aria-label="breadcrumb" separator="/">
                    <Link underline="hover" className="text-gray-900 flex " to="/"><HomeOutlinedIcon className="text-dark-500" fontSize="small" /></Link>
                    <GrantContent permisos={[Permissions.APP_MONITORING_R]}>
                        {(grant) => {
                            return grant
                                ? <Link underline="hover" className="text-gray-900" to={`/projects/${project}/monitoring/apps`}>App Monitoring</Link>
                                : <Typography ><span className="text-gray-600">App Monitoring</span></Typography>
                        }}
                    </GrantContent>
                    <Typography color="">{state.name}</Typography>
                </Breadcrumbs>
            </Header>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="content grid gap-8">
                    <div className="grid gap-8" >
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%' }}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
                                            <Tab label="General" icon={<SourceIcon />} iconPosition="start" />
                                            <Tab label="Delete" icon={<DeleteIcon />} iconPosition="start" disabled={state.new} />
                                        </Tabs>
                                    </Box>
                                </Box>

                                <div className="p-4">
                                    {tab === 0 && <EditAppMonitorGenerals form={form} />}

                                </div>


                                {tab === 1 && (
                                    <form onSubmit={event => deleteAppMonitor(event, navigate)}>
                                        <div className="grid gap-4 p-4">
                                            <p>Deleting the project is an irreversible action and will delete all collections, documents, users, tokens, etc.</p>
                                            <TextField
                                                required
                                                size="medium"
                                                name="deleteProjectName"
                                                label="Project name"
                                                className="w-96"
                                                helperText={<p>Enter the project name <em className="text-red">'{state.name}'</em> to confirm the action</p>}
                                                value={state.deleteProjectName}
                                                onInput={event => createTextKey(event, 60)}
                                                onChange={updateGenerales}
                                            />
                                            <Button type="submit" className="w-96" variant="contained" color="error">DELETE</Button>
                                        </div>
                                    </form>
                                )}
                            </Paper>
                        </Box>

                        <div className="text-right">
                            <Button type="submit" variant="contained" className="text-dark-500">SAVE</Button>
                        </div>
                    </div>
                </div>
            </form>

            <DevTool control={control} />
        </div>
    )

}


export default EditAppMonitor