import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import React from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ConfirmDialog = (props) => {

    const { open, title, message, onClose, onConfirm, closeMessage = "CANCEL", confirmMessage = "CONTINUE" } = props

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className=""
        >
            <DialogTitle sx={{ m: 0, p: 2 }} className="bg-red">
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <HighlightOffIcon className="text-dark-500" />
            </IconButton>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>{closeMessage}</Button>
                <Button variant="contained" color="error" onClick={onConfirm}>{confirmMessage}</Button>
            </DialogActions>
        </Dialog>
    )

}

export default ConfirmDialog