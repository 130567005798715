import React, { useEffect, useState } from 'react'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Paper, TextField, ThemeProvider } from '@mui/material';
import { MaterialActionElement, MaterialShiftActions, MaterialValueEditor, MaterialValueSelector, QueryBuilderMaterial } from '@react-querybuilder/material';
import { defaultOperators, defaultRuleProcessorMongoDB } from 'react-querybuilder';
import { QueryBuilder as MUIQueryBuilder, formatQuery, transformQuery } from 'react-querybuilder';
import { createTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import 'react-querybuilder/dist/query-builder.css';

const MuiOutlinedValueEditor = (props) => (
    <MaterialValueEditor {...props} extraProps={{ variant: 'outlined', size: "small" }} />
);

const MuiOutlinedValueSelector = (props) => {
    return <MaterialValueSelector {...props} variant="outlined" size="small" />
}


const MuiOutlinedActionElement = (props) => (
    <MaterialActionElement {...props} variant="outlined" size="small" className="!bg-green-500 !text-dark-900 h-full" />
)

const FieldSelector = (props) => {

    return <Autocomplete
        title={props.title}
        value={props.value}
        size="small"
        className="field-selector"
        id={`field-selector-${props.value}`}
        freeSolo
        options={props.options}
        PaperComponent={(props) => <Paper  {...props} id="XXXXXX" width={{ width: "500px !important" }} />}
        renderOption={(props, option, state, ownerState) => {
            return (
                <li {...props} >
                    <div className="flex flex-row items-center w-full gap-2">
                        <div className="flex-1">
                            <p className="block w-full text-sm">{option.name || option}</p>
                            <p className="text-xs text-gray-600">{option.placeholder}</p>
                        </div>
                        <span className="text-xs bg-gray-600 rounded-full p-1 m-0">{option.inputType}</span>
                    </div>
                </li>
            )
        }}
        getOptionLabel={option => option.name || option}
        getOptionKey={option => option.name}
        onInputChange={(event, newInputValue) => {
            if (event && (event.type === "click" || (event.type === "keydown" && event.key === "Enter"))) {
                props.handleOnChange(newInputValue)
            }
        }}
        renderInput={(params) => (
            <TextField
                {...params}

                //{...props}
                className={props.className}
                disabled={props.disabled}
                placeholder={props.title}

                label="Field"
                onChange={(e) => props.handleOnChange(e.target.value)}
                onFocus={(event) => event.target.select()}
            />
        )}
    />
}

// InputType = "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week" | (string & {});
// "=" | "!=" | "<" | ">" | "<=" | ">=" | "contains" | "beginsWith" | "endsWith" | "doesNotContain" | "doesNotBeginWith" | "doesNotEndWith" | "null" | "notNull" | "in" | "notIn" | "between" | "notBetween";
export const datetimeOperators = [
    ...defaultOperators.filter(op => ['=', '!=', '<', '>', '<=', '>=', 'between', 'notBetween'].includes(op.name))
]

export const keyOperators = [
    ...defaultOperators.filter(op => ['='].includes(op.name))
]

export const getOperators = (fieldType) => {
    
    switch (fieldType) {
        case "string":
            return defaultOperators
        case "datetime":
            return datetimeOperators
        case "metadata":
            return [...defaultOperators.filter(op => ['='].includes(op.name))]
    }
}

const customRuleProcessor = (field, options, meta, fields) => {
    const regexDatetime = /"(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})"/g;
    const regexDate = /"(\d{4}-\d{2}-\d{2})"/g;

    let _field = field;
    if (!fields.map(x => x.name).includes(field.field)) {
        let value = ""
        if (typeof field === "string" && field.startsWith("\"") && field.endsWith("\"")) {
            value = "/" + field.substring(1, field.length - 1) + "/i"
        } else {
            try {
                value = Number(field.value);
                if (isNaN(value)) {
                    value = field.value
                }
            } catch (error) {
                value = field.value
            }
        }

        _field = {
            ...field,
            value: value,
            field: `${field.field}`
            //field: `${field.field}`
        }
    }

    let value = defaultRuleProcessorMongoDB(_field, options);

    //value = value.replace(regexDatetime, (match, p1) => `ISODate('${p1}:00.000Z')`);
    //value = value.replace(regexDate, (match, p1) => `ISODate('${p1}'):00.000Z`);
    //value = value.replaceAll(`\\"`, ``);
    return value;

    // 2024-10-01T13:24:00.000Z

};

const ruleProcessorRec = (r, context) => {
    return {
        ...r,
        inputType: context?.fieldData?.inputType,
    }
};


const muiTheme = createTheme({});

const QueryBuilder = ({ fields, initialQuery, onApply, cleanFilters, fireInitialApply = true }) => {

    const [expand, setExpand] = useState(true)
    const [debug, setDebug] = useState(false)
    const [query, setQuery] = useState(initialQuery);

    useEffect(() => {
        if (fireInitialApply) {
            _onApply();
        }
    }, [])

    
    const _onApply = () => {
        if(!query) return;

        const q = formatQuery(query, {
            format: "mongodb",
            valueProcessor: defaultRuleProcessorMongoDB,
            ruleProcessor: (rule, options, meta) => customRuleProcessor(rule, options, meta, fields)
        })
        onApply(q, query);
    }



    return (
        <div className="grid">
            <div className="hidden col-span-1 md:col-span-2 lg:col-span-3" />
            <Accordion expanded={expand} onChange={() => setExpand(!expand)} className="rounded-none shadow-none">
                <AccordionSummary expandIcon={<ExpandMoreIcon className="text-gray-400" />} >
                    <p className="font-bold "><FilterAltIcon /> Filters </p>
                </AccordionSummary>
                <AccordionDetails className="rounded-none shadow-none">
                    <div className="grid gap-8">
                        <ThemeProvider theme={muiTheme}>
                            <QueryBuilderMaterial  >
                                <MUIQueryBuilder
                                    fields={fields}
                                    query={query}
                                    onQueryChange={setQuery}
                                    resetOnFieldChange={true}
                                    resetOnOperatorChange={false}
                                    parseNumbers={'strict-limited'}
                                    autoSelectField={true}
                                    autoSelectOperator={"="}
                                    controlClassnames={{ queryBuilder: 'justifiedLayout queryBuilder-branches' }}
                                    controlElements={{
                                        valueEditor: MuiOutlinedValueEditor,
                                        valueSelector: MuiOutlinedValueSelector,
                                        actionElement: MuiOutlinedActionElement,
                                        fieldSelector: FieldSelector
                                    }}
                                />
                            </QueryBuilderMaterial>
                        </ThemeProvider>

                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={debug}
                                        control={<Checkbox checked={debug} name="debug" />}
                                        label="Show debug query"
                                        onChange={() => setDebug(!debug)}
                                    />
                                </FormGroup>
                            </FormControl>
                            <div className="grid grid-cols-2 gap-4 md:col-start-4">
                                <Button variant="outlined" onClick={cleanFilters}>CLEAN</Button>
                                <Button variant="contained" onClick={_onApply} >APPLY</Button>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
                <AccordionActions className="!m-0 !p-0">
                    {debug && (
                        <div className="bg-gray-200 w-full  p-4 ">
                            <pre className="max-w-full text-wrap text-xs">
                                {formatQuery(query, {
                                    format: "mongodb",
                                    //parseNumbers: parseNumber,
                                    //valueProcessor: defaultRuleProcessorMongoDB,
                                    ruleProcessor: (rule, options, meta) => customRuleProcessor(rule, options, meta, fields)
                                })}
                            </pre>
                        </div>
                    )}
                </AccordionActions>
            </Accordion >
        </div>
    )
}

export default QueryBuilder;