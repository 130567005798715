import { Box, Breadcrumbs, Button, Checkbox, FormControl, FormControlLabel, Tooltip, FormGroup, Paper, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SourceIcon from '@mui/icons-material/Source';
import TaskIcon from '@mui/icons-material/Task';
import { useSelector } from 'react-redux';
import { reset, init, save, updateGenerales } from './edit-collections-actions';
import AppContext from '../../state/AppContext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as Permissions from '../../utils/Permissions'
import GrantContent from '../../template/GrantContent';
import { createTextKey } from '../../utils/StringUtils';
import RuleIcon from '@mui/icons-material/Rule';
import Header from '../../template/Header';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EditCollectionExpressionRules from './EditCollectionExpressionRules';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditCollectionMetadataMapper from './EditCollectionMetadataMapper';


const EditCollection = (props) => {


    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const { collection } = useParams()
    const [tab, setTab] = useState(0)
    const state = useSelector(state => state.pages.editCollection)

    const navigate = useNavigate()

    useEffect(() => {
        if (collection !== "_new") {
            init(project, collection)
        }

        return () => reset()
    }, [collection])

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div className="">
            <Header title={<span className="capitalize">{collection}{state.new ? "*" : ""}</span>} >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Collections</span></Typography>
                    <Typography ><span className="text-gray-600">{collection}{state.new ? "*" : ""}</span></Typography>
                </Breadcrumbs>
            </Header>

            <div className="content">
                <div className="grid gap-8" >
                    <div className="text-right">
                        <GrantContent permisos={[Permissions.COLLECTIONS_R]}>
                            <Button variant="contained" LinkComponent={Link} to={`/projects/${project}/collections/${collection}`}> <VisibilityIcon className="mr-1" /> VER COLLECTION</Button>
                        </GrantContent>
                    </div>

                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
                                        <Tab label="General" icon={<SourceIcon />} iconPosition="start" />
                                        <Tab label="Schema" disabled={state.new} icon={<TaskIcon />} iconPosition="start" />
                                        <Tab label="Rules" disabled={state.new} icon={<RuleIcon />} iconPosition="start" />
                                        <Tab label="Metadata Mapper" disabled={state.new} icon={<AutoFixHighIcon />} iconPosition="start" />
                                    </Tabs>
                                </Box>
                            </Box>

                            {tab === 0 && (
                                <div className="grid gap-4 p-4">
                                    <div className="grid grid-cols-3 gap-4">
                                        <TextField
                                            size="medium"
                                            name="name"
                                            label="name"
                                            onInput={event => createTextKey(event, 60)}
                                            disabled={!state.new}
                                            value={state.name}
                                            onChange={updateGenerales}
                                        />
                                    </div>
                                    <div className="flex items-center">
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    name="skipUnchangedDocuments"
                                                    value={state.skipUnchangedDocuments}
                                                    control={<Checkbox checked={state.skipUnchangedDocuments} />}
                                                    label="Skip Unchanged Documents"
                                                    onChange={updateGenerales}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <Tooltip title="Avoid saving documents that have no changes compared to the previous revision">
                                            <HelpOutlineIcon />
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                            {tab === 1 && (
                                <div className="grid  gap-4 p-4">


                                    <TextField
                                        size="medium"
                                        name="schema"
                                        label="schema"
                                        placeholder={
                                            `{
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "$id": "https://example.com/product.schema.json",
    "title": "Product",
    "description": "A product from Acme's catalog",
    "type": "object",
    "properties": {
        "productId": {
        "description": "The unique identifier for a product",
        "type": "integer"
        }
    },
    "required": [
        "productId"
    ],
    "additionalProperties": true
}`
                                        }
                                        multiline
                                        helperText={<p>Use JSON Schema to validate documents, learn more on <a className="text-green-500" target="_blank" href="https://json-schema.org/">https://json-schema.org/</a></p>}
                                        rows={10}
                                        value={state.schema}
                                        onChange={updateGenerales}
                                    />

                                    <FormControl component="fieldset">
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox checked={state.useSchema} name="useSchema" />}
                                                label="Validate document schema"
                                                onChange={updateGenerales}
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>

                                    <FormControl component="fieldset">
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox checked={state.rejectInvalid} name="rejectInvalid" />}
                                                label="Reject invalid documents"
                                                onChange={updateGenerales}
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </div>
                            )}
                            {tab === 2 && (
                                <EditCollectionExpressionRules />
                            )}
                            {tab === 3 && (
                                <EditCollectionMetadataMapper />
                            )}
                            <div className="p-4 text-right bg-gray-100 border-t border-gray-300">
                                <Button variant="contained" className="text-dark-500" onClick={() => save(project, navigate)}>SAVE</Button>
                            </div>
                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    )

}


export default EditCollection