import React from 'react'
import SupportIcon from '@mui/icons-material/Support';
import { Link } from 'react-router-dom';

const Footer = (props) => {

    return (
        <div className="absolute bottom-0 left-0 right-0 bg-red">
            <footer className="flex justify-center items-center h-10 bg-gray-200 border-t border-gray-500 px-4">
                <p className="m-0 text-sm flex-1 text-center">GoBack - Compliance system</p>
                <Link to={`/support`} className="flex text-sm no-underline"><SupportIcon fontSize="small" /> Support</Link>
            </footer>
        </div>
    )
}

export default Footer;