import React, { useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { useLocation, useParams } from 'react-router-dom'
import { Box, Breadcrumbs, Paper, TextField, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// https://json-diff-kit.js.org/#l=%7B%0A++%22noPoliza%22%3A+%221234567890%22%2C%0A++%22total%22%3A+100000%2C%0A++%22iva%22%3A+10000%2C%0A++%22cliente%22%3A+%22Oscar+Blancarte%22%2C%0A++%22rfc%22%3A+%22BAIO8601201I5%22%0A%7D&r=%7B%0A++%22noPoliza%22%3A+%221234567890%22%2C%0A++%22total%22%3A+100000%2C%0A++%22iva%22%3A+2313%2C%0A++%22cliente%22%3A+%22Oscar+Blancarte%22%2C%0A++%22rfc%22%3A+%22BAIO8601201I5%22%0A%7D
import { Differ, Viewer } from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import AppContext from '../../state/AppContext';
import { setLoading } from '../../template/app-actions';
import toast from 'react-hot-toast';
import Tour from 'reactour'
import Header from '../../template/Header';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const differ = new Differ({
    detectCircular: true,    // default `true`
    maxDepth: Infinity,      // default `Infinity`
    showModifications: true, // default `true`
    arrayDiffMethod: 'lcs',  // default `"normal"`, but `"lcs"` may be more useful
    sortResultLines: false,
    preserveKeyOrder: 'after'
});

const steps = [
    {
        selector: '[data-tut="first-step"]',
        content: 'To compare two versions, you will have to select the base version',
    }, {
        selector: '[data-tut="second-step"]',
        content: 'You must then select the version with which to purchase the base document.',
    }, {
        selector: '[data-tut="tree-step"]',
        content: 'You will be able to see the general data of each review',
    }, {
        selector: '[data-tut="four-step"]',
        content: 'In this section you will see the differences between two reviews',
    }
]


const Diff = (props) => {

    const [state, setState] = useState(undefined)
    const [revision, setRevision] = useState(null)
    const [compareRevision, setCompareRevision] = useState(null)
    const [diff, setDiff] = useState(null)
    const [showTour, setShowTour] = useState(window.localStorage.getItem(`page-diff.tour`) === null)
    const { collection, documentId } = useParams()
    const { appContext } = useContext(AppContext)
    const project = appContext.project


    useEffect(() => {
        try {
            setLoading(true)
            init()
        } catch (error) {
            toast.error("Unexpected error")
        } finally {
            setLoading(false)
        }
    }, [documentId])

    useEffect(() => {
        if (documentId && revision?.revision && compareRevision?.revision) {
            try {
                setLoading(true)
                fetchDiff(documentId, compareRevision.revision, revision.revision)
            } catch (error) {
                toast.error("Unexpected error")
            } finally {
                setLoading(false)
            }
        }
    }, [compareRevision?.revision])

    const fetchDiff = async (documentId, base, target) => {
        const response = await (await APIInvoke.invokeGET(`/projects/${project}/collections/${collection}/documents/${documentId}/diff/${base}/${target}`)).json()
        setDiff(response.body)
    }

    const init = async () => {
        const response = await (await APIInvoke.invokeGET(`/projects/${project}/collections/${collection}/documents/${documentId}`)).json()
        setState(response.body)

        const currentRev = response.body.revisions[0]
        setRevision(currentRev)

        if (response.body.revisions.length > 1) {
            setCompareRevision(response.body.revisions[1])

        }
    }

    const baseRevisionChange = (event) => {
        const revisionIndex = state.revisions.findIndex(rev => rev.revision === event.target.value)
        const revision = state.revisions[revisionIndex]

        setRevision(revision);

        if (state.revisions.length > revisionIndex + 1) {
            setCompareRevision(state.revisions[revisionIndex + 1])
        } else {
            setCompareRevision(null)
            setDiff(null)
        }
    }

    const targetRevisionChange = (event) => {
        const newRevision = state.revisions.find(rev => rev.revision === event.target.value)
        setCompareRevision(newRevision);
        fetchDiff(documentId, newRevision.revision, revision.revision)
    }

    const handleCloseTour = () => {
        setShowTour(false)
        window.localStorage.setItem("page-diff.tour", "true")
    }

    return (
        <div className="">
            <Header title={<span>{state?.key}<span className="text-xs text-gray-700">/ {documentId} </span></span>} >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600" >
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Collections</span></Typography>
                    <Typography ><span className="text-gray-600">{collection}</span></Typography>
                    <Typography ><span className="text-gray-600">{documentId}</span></Typography>
                    <Typography ><span className="text-gray-600">Diff</span></Typography>
                </Breadcrumbs>
            </Header>
            {state && (
                <div className="content grid gap-8">
                    <div >
                        <div className="grid gap-8">
                            <div className="grid grid-cols-2 gap-4">
                                <FormControl size="medium" data-tut="first-step" className="flex-1">
                                    <InputLabel id="revision-label">Revision</InputLabel>
                                    <Select
                                        className="bg-white"
                                        labelId="revision-label"
                                        id="revision"
                                        value={revision.revision}
                                        label="Revision"
                                        onChange={baseRevisionChange}
                                    >
                                        {state.revisions.map((rev, index) => (
                                            <MenuItem key={index} value={rev.revision}><span className="text-green-500">Rev {`${rev.revision}`.padStart(2, '0')}</span> - <span className="text-sm text-red">{rev.createAt}</span> <span className="text-blue-500">{rev.createBy}</span></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl size="medium" data-tut="second-step" className="flex-1">
                                    <InputLabel id="revision-label">Compare To</InputLabel>
                                    <Select
                                        className="bg-white"
                                        labelId="revision-label"
                                        id="revision"
                                        value={compareRevision?.revision}
                                        label="Revision"
                                        onChange={targetRevisionChange}
                                    >
                                        {state.revisions.map((rev, index) => (
                                            <MenuItem disabled={rev.revision >= revision.revision} key={index} value={rev.revision}><span className="text-green-500">Rev {`${rev.revision}`.padStart(2, '0')}</span> - <span className="text-sm text-red">{rev.createAt}</span> <span className="text-blue-500">{rev.createBy}</span></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="grid grid-cols-3 gap-4 " data-tut="tree-step">
                                <Box sx={{ width: '100%', height: "100%" }} >
                                    <Paper sx={{ width: '100%', height: "100%" }}>
                                        {compareRevision && (


                                            <div className="grid gap-4 p-4 rounded-md">
                                                <TextField
                                                    size="small"
                                                    label="Create By"
                                                    disabled
                                                    value={compareRevision?.createBy || ''}
                                                />
                                                <TextField
                                                    size="small"
                                                    label="Create At"
                                                    disabled
                                                    value={compareRevision?.createAt || ''}
                                                />
                                                <TextField
                                                    size="small"
                                                    label="Received At"
                                                    disabled
                                                    value={compareRevision?.receivedAt || ''}
                                                />
                                                <TextField
                                                    size="small"
                                                    label="Lines"
                                                    disabled
                                                    value={JSON.stringify(compareRevision?.payload, null, 2).split('\n').length}
                                                />
                                            </div>
                                        )}
                                    </Paper>
                                </Box>
                                <Box sx={{ width: '100%', height: "100%" }}>
                                    <Paper sx={{ width: '100%', height: "100%" }}>
                                        <div className="grid gap-4 p-4 rounded-md">
                                            <div>
                                                <p className="font-bold">Change rate of</p>
                                                <p className="text-sm">{(100.0 - diff?.similarityRate).toFixed(2)}%</p>
                                            </div>
                                            <div>
                                                <p className="font-bold">Last action</p>
                                                <p className="text-sm">{state.lastAction}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                </Box>
                                <Box sx={{ width: '100%', height: "100%" }}>
                                    <Paper sx={{ width: '100%', height: "100%" }}>
                                        <div className="grid gap-4 p-4 rounded-md">
                                            <TextField
                                                size="small"
                                                label="Create By"
                                                disabled
                                                value={revision.createBy || ''}
                                            />
                                            <TextField
                                                size="small"
                                                label="Create At"
                                                disabled
                                                value={revision.createAt || ''}
                                            />
                                            <TextField
                                                size="small"
                                                label="Received At"
                                                disabled
                                                value={revision.receivedAt || ''}
                                            />
                                            <TextField
                                                size="small"
                                                label="Lines"
                                                disabled
                                                value={JSON.stringify(revision?.payload, null, 2).split('\n').length}
                                            />
                                        </div>
                                    </Paper>
                                </Box>
                            </div>

                            {diff && (
                                <Box sx={{ width: '100%' }} data-tut="four-step">
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <div>
                                            <div className="compare-rev-div">
                                                <Viewer
                                                    className="bg-white text-dark-500"
                                                    diff={differ.diff(compareRevision.payload, revision.payload)}          // required
                                                    indent={4}                 // default `2`
                                                    lineNumbers={true}         // default `false`
                                                    highlightInlineDiff={true} // default `false`
                                                    hideUnchangedLines={false}
                                                    inlineDiffOptions={{
                                                        mode: 'word',            // default `"char"`, but `"word"` may be more useful
                                                        wordSeparator: ' ',      // default `""`, but `" "` is more useful for sentences
                                                    }}
                                                    syntaxHighlight={{
                                                        theme: ''
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Paper>
                                </Box>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <Tour steps={steps} isOpen={showTour} onRequestClose={handleCloseTour} closeWithMask={false} />
        </div>
    )

}

export default Diff