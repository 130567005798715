import { Box, Breadcrumbs, Button, Paper, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, IconButton, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SourceIcon from '@mui/icons-material/Source';
import { useSelector } from 'react-redux';
import { reset, init, save, updateGenerales, onDeleteDS, toggleDeleteTokenDialog, onDeleteToken, deleteProject } from './edit-project-actions';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../template/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import KeyIcon from '@mui/icons-material/Key';
import AppContext from '../../state/AppContext';
import { createTextKey } from '../../utils/StringUtils';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';

const columns = [
    {
        field: 'name',
        headerName: 'Name'
    }, {
        field: 'expiration',
        headerName: 'Expiration'
    }, {
        field: '',
        headerName: 'Actions',
        type: 'string'
    }
]


const EditProject = (props) => {

    const state = useSelector(state => state.pages.editProject)
    const { appContext } = useContext(AppContext)
    const { project } = useParams()
    const [tab, setTab] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        if (project !== "_new") {
            init(project)
        }

        return () => reset()
    }, [project])

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div className="">
            <Header title={<span>{project}{state.new ? "*" : ""}</span>}>
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Projects</span></Typography>
                    <Typography ><span className="text-gray-600">{project}</span></Typography>
                </Breadcrumbs>
            </Header>
            <ConfirmDialog title="Delete token" message="This action is permanent and there will be no way to recover the token" open={state.showDeleteDialog.open} onClose={toggleDeleteTokenDialog} onConfirm={() => onDeleteToken(project)} />
            <div className="content grid gap-8">
                <div className="grid gap-8" >
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
                                        <Tab label="General" icon={<SourceIcon />} iconPosition="start" />
                                        <Tab label="API Token" icon={<KeyIcon />} iconPosition="start" disabled={state.new} />
                                        <Tab label="Delete" icon={<DeleteIcon />} iconPosition="start" disabled={state.new} />
                                    </Tabs>
                                </Box>
                            </Box>

                            {tab === 0 && (
                                <div className="p-4">
                                    <TextField
                                        size="medium"
                                        name="name"
                                        label="name"
                                        disabled={!state.new}
                                        value={state.name}
                                        onInput={event => createTextKey(event, 60)}
                                        onChange={updateGenerales}
                                    />
                                </div>
                            )}

                            {tab === 1 && (
                                <div className="grid gap-8 p-4">
                                    <p>API Tokens</p>
                                    <div className="text-right">
                                        <Button variant="contained" LinkComponent={Link} to={`/projects/${project}/tokens/new`}><AddIcon /> NEW TOKEN</Button>
                                    </div>
                                    <Box >
                                        <Paper >
                                            <TableContainer>
                                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((headCell) => (
                                                                <TableCell key={headCell.field} align={'left'} padding={'normal'} >
                                                                    <TableSortLabel >
                                                                        {headCell.headerName}
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {state.tokens.map((row, index) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                    sx={{ cursor: 'pointer' }}
                                                                >
                                                                    <TableCell align="left">{row.name}</TableCell>
                                                                    <TableCell align="left">{row.expiration ? row.expiration : <em>Never</em>}</TableCell>
                                                                    <TableCell width={130} align="right">
                                                                        <Tooltip title="Delete">
                                                                            <IconButton onClick={(event) => toggleDeleteTokenDialog(event, row)} ><DeleteIcon className="hover:text-red" /></IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </div>
                            )}

                            {tab === 2 && (
                                <form onSubmit={event => deleteProject(event, navigate)}>
                                    <div className="grid gap-4 p-4">
                                        <p>Deleting the project is an irreversible action and will delete all collections, documents, users, tokens, etc.</p>
                                        <TextField
                                            required
                                            size="medium"
                                            name="deleteProjectName"
                                            label="Project name"
                                            className="w-96"
                                            helperText={<p>Enter the project name <em className="text-red">'{state.name}'</em> to confirm the action</p>}
                                            value={state.deleteProjectName}
                                            onInput={event => createTextKey(event, 60)}
                                            onChange={updateGenerales}
                                        />
                                        <Button type="submit" className="w-96" variant="contained" color="error">DELETE</Button>
                                    </div>
                                </form>
                            )}
                        </Paper>
                    </Box>

                    <div className="text-right">
                        <Button variant="contained" className="text-dark-500" onClick={() => save(project, navigate)}>SAVE</Button>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default EditProject