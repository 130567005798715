import { Handle, Position } from "@xyflow/react"

const TLogNode = ({ data, isConnectable }) => {

    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div className="border-dark-900 border py-2 px-4 rounded-sm bg-white">
                <p className="text-xs">{data.label}</p>
            </div>
        </>
    )
}

export default TLogNode