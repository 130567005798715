import { Box, Button, Chip, FormControl, Paper, Stack, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import { addNotifyTo, addRule, removeNotifyTo, updateRulesConfig } from './edit-collections-actions';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { Editor } from '@monaco-editor/react';
import EditCollectionExpressionRule from './EditCollectionExpressionRule';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const EditCollectionExpressionRules = (props) => {

    const state = useSelector(state => state.pages.editCollection)
    const rulesConfig = state.rulesConfig;
    const rules = rulesConfig.rules

    const [uiState, setUiState] = useState({
        validEmail: true
    });

    const validateEmailInput = (event) => {
        if (event.key === "Enter") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(event.target.value)) {
                setUiState({ validEmail: false })
            } else {
                setUiState({ validEmail: true })
                addNotifyTo(event)
            }
        }
    }

    return (
        <form>
            <div className="grid gap-8 p-4 ">
                <p className="text-md border-b pb-4 text-gray-800">Rules allow you to identify certain patterns in documents to alert administrators about suspicious behavior or behavior that requires observation.</p>
                <div className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-2 gap-4">
                        <FormControl variant="outlined" size="large" fullWidth={true}>
                            <TextField
                                error={uiState.validEmail === false}
                                type="email"
                                size="medium"
                                label="Entrar email user to notify"
                                helperText={"Enter the email of the users who will be notified when a rule is triggered"}
                                onKeyDown={validateEmailInput}
                            />
                        </FormControl>
                    </div>

                    <div>
                        <Stack direction="row" spacing={1}>
                            {rulesConfig.notifyTo.map(user => (
                                <Chip key={user} label={user} variant="outlined" onDelete={e => removeNotifyTo(user)} />
                            ))}
                        </Stack>
                    </div>

                    <div className="border-b border-gray-300 my-4" />

                    <div className="grid grid-cols-2 gap-4">
                        <div className="grid gap-2">
                            <p>Current Document example <Tooltip title="This example corresponds to the new document created"><HelpOutlineIcon /></Tooltip></p>
                            <Box className="h-full">
                                <Paper className="h-full">
                                    <Editor
                                        options={{
                                            selectOnLineNumbers: true,
                                            automaticLayout: true,
                                            minimap: {
                                                enabled: false
                                            },
                                            lineNumbers: 'on'
                                        }}
                                        height="300px"
                                        language="json"
                                        theme="git"
                                        className="h-full"
                                        value={rulesConfig.rulesExample}
                                        onChange={(newValue, e) => updateRulesConfig({ target: { name: "rulesExample", type: "text", value: newValue } })}
                                    />
                                </Paper>
                            </Box>
                            <p className="text-gray-700 text-xs px-4">Use a real JSON document to validate your rules, use $ to reference to this document</p>
                        </div>
                        <div className="grid gap-2">
                            <p>Preview Document example <Tooltip title="This document corresponds to the previous version of the document created"><HelpOutlineIcon /></Tooltip></p>
                            <Box className="h-full">
                                <Paper className="h-full">
                                    <Editor
                                        options={{
                                            selectOnLineNumbers: true,
                                            automaticLayout: true,
                                            minimap: {
                                                enabled: false
                                            },
                                            lineNumbers: 'on'
                                        }}
                                        height="300px"
                                        language="json"
                                        theme="git"
                                        className="h-full"
                                        value={rulesConfig.rulesPreviewExample}
                                        onChange={(newValue, e) => updateRulesConfig({ target: { name: "rulesPreviewExample", type: "text", value: newValue } })}
                                    />
                                </Paper>
                            </Box>
                            <p className="text-gray-700 text-xs px-4">Use a real JSON document to validate your rules, use @ to reference to this document</p>
                        </div>
                    </div>

                    <div className="border-b border-gray-300 my-4" />

                    <div className="grid gap-4">
                        <p>Rules</p>
                        <div className="grid gap-8">
                            {rules?.map(rule => <EditCollectionExpressionRule key={rule.id} rule={rule} />)}
                        </div>

                        <div className="text-right">
                            <Button variant="contained" onClick={addRule} ><AddIcon />ADD RULE</Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default EditCollectionExpressionRules;