const config = {
    api: {
        //baseUrl: "https://goback-api-1070586186100.us-central1.run.app", // Localhost
        baseUrl: "https://goback-api-648139829704.us-central1.run.app" // AXA Production
    },
    contacts: {
        emailContacto: "info@goback.mx",
        emailSoporte: "info@goback.mx"
    }
}


module.exports = config