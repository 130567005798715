import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { hasValue } from '../../../utils/StringUtils'
import { useState } from 'react'
import { useEffect } from 'react'

const SelectFilter = ({
    id,
    name,
    placeholder,
    values,
    value,
    processFilter,
    getOptionLabel,
    getOptionId,
    updateState,
    required,
    lastCommitedState
}) => {

    const [options, setOptions] = useState([])

    useEffect(() => {
        const fetchOptions = async () => {
            const optionsResponse = await values
            refreshOptions(optionsResponse)
        }

        if (values instanceof Promise) {
            fetchOptions()
        } else {
            refreshOptions(values)
        }
    }, [values])

    const refreshOptions = (options) => {
        setOptions(options)
    }


    const render = () => {
        return (
            <FormControl variant="outlined" size="small" fullWidth={true} >
                <InputLabel htmlFor={id} required={required}>{placeholder}</InputLabel>
                <Select
                    id={id}
                    name={name}
                    size="small"
                    fullWidth={true}
                    label={placeholder}
                    value={value}
                    onChange={(e) => { updateState(id, e.target.value, true) }}
                    endAdornment={
                        hasValue(value) && (
                            <InputAdornment position="end">
                                <IconButton
                                    className="hover:text-green-500"
                                    aria-label="limpiar"
                                    onClick={(e) => { updateState(id, "", true) }}
                                    edge="end"
                                >
                                    <CancelIcon className="hover:text-green-500" />
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                >
                    <MenuItem value=""><em>Seleccione</em></MenuItem>
                    {options.map(item => <MenuItem key={item.key} value={item}>{item.icon && <span className="mr-2 text-dark-600">{item.icon}</span>}{item.value}</MenuItem>)}
                </Select>
            </FormControl>
        )
    }

    return render()

}

SelectFilter.processInput = (option) => option.key
SelectFilter.getOptionId = (option) => option.key
SelectFilter.getOptionLabel = (option) => option.value
SelectFilter.isOptionEqualToValue = (option1, option2) => option1?.key === option2?.key

export default SelectFilter