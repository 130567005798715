import { Box, Breadcrumbs, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import AppContext from '../../state/AppContext';
import { setLoading } from '../../template/app-actions';
import FilterBuilder from '../../components/FilterBuilder/FilterBuilder';
import TextFilter from '../../components/FilterBuilder/controls/TextFilter';
import { hasText } from '../../utils/StringUtils';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';
import { useSelector } from 'react-redux';

const columns = [
    {
        field: 'collection',
        headerName: 'Collection',
        type: 'string',
        align: 'left'
    }, {
        field: 'documentKey',
        headerName: 'Key',
        type: 'string',
        align: 'left'
    }, {
        field: 'revisionNo',
        headerName: 'Revision',
        type: 'string',
        align: 'left'
    }, {
        field: 'rule',
        headerName: 'Rule',
        type: 'string',
        align: 'left'
    }, {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        align: 'left'
    }, {
        field: 'eventTime',
        headerName: 'Alert Time',
        type: 'string',
        align: 'left'
    }
]

const filters = [
    {
        id: "collection",
        name: "Collection",
        placeholder: "Collection",
        filter: "collection[cn]",
        type: TextFilter,
        defaultValue: ""
    }
]

const initialState = {
    queryBuilder: {
        queryString: `?sort=eventTime,desc`
    },
    pageSize: 10,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    items: []
}

const CollectionsAlerts = (props) => {


    const navigate = useNavigate();
    const [data, setData] = useState(initialState)
    const { appContext } = useContext(AppContext)
    const { loading } = useSelector(state => state.pages.app)
    const project = appContext.project

    useEffect(() => {
        try {
            setLoading(true)
            init(data)
        } catch (error) {
            console.error("Projects.useEffect => ", error)
        } finally {
            setLoading(false)
        }
    }, [project, data.currentPage, data.pageSize])

    const init = async (newState) => {
        try {
            setLoading(true)
            const query = newState.queryBuilder.queryString
            const response = await (await APIInvoke.invokeGET(`/projects/${project}/collections/alerts${newState.queryBuilder.queryString}${hasText(query) ? "&" : "?"}page=${newState.currentPage}&size=${newState?.pageSize || 10}&sort=name,desc`)).json()
            if (response.ok) {
                setData({
                    ...newState,
                    items: response.body.items,
                    totalItems: response.body.totalItems,
                    currentPage: response.body.currentPage,
                    totalPages: response.body.totalPages,
                    pageSize: response.body.pageSize
                });
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.error("Error lo load page", error)
        } finally {
            setLoading(false)
        }
    }

    const onFilterProcess = async (queryBuilder) => {
        try {
            setLoading(true)
            const newState = {
                ...data,
                queryBuilder: queryBuilder,
                totalItems: 0,
                currentPage: 0,
                totalPages: 0,
                pageSize: 10,
            }
            setData(newState)
            init(newState)
        } catch (error) {
            console.error("CollectionsAlerts.onFilterProcess => ", error)
        } finally {
            setLoading(false)
        }   
        
    }

    const onRowClick = (event, collection, documentId) => {
        navigate(`/projects/${project}/collections/${collection}/documents/${documentId}`, { state: { backLink: -1 } })
    }

    const handleChangePage = (e, newPage) => {
        setData({
            ...data,
            currentPage: newPage
        })
    }

    const onRowsPerPageChange = (event) => {
        setData({
            ...initialState,
            items: data.items,
            pageSize: parseInt(event.target.value, 10)
        })
    }

    return (
        <div className="">
            <Header title="Collections Alerts" >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Collections Alerts</span></Typography>
                </Breadcrumbs>
            </Header>

            <div className="content ">
                <div className="grid gap-8">

                    <div >
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <div>
                                    <FilterBuilder filters={filters} onProcess={onFilterProcess} />
                                </div>
                                {loading && <LinearProgress size="sm" />}
                                <TableContainer>
                                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((headCell) => (
                                                    <TableCell key={headCell.field} align={headCell.align} padding={'normal'} >
                                                        <TableSortLabel >
                                                            {headCell.headerName}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.items.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => onRowClick(event, row.collection, row.documentId)}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell align="left">{row.collection}</TableCell>
                                                        <TableCell align="left">{row.documentKey}</TableCell>
                                                        <TableCell align="left">{row.revisionNo}</TableCell>
                                                        <TableCell align="left">{row.rule.name}</TableCell>
                                                        <TableCell align="left">{row.rule.description}</TableCell>
                                                        <TableCell align="left">{row.eventTime}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {loading && <LinearProgress size="sm" />}
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50]}
                                    component="div"
                                    rowsPerPage={data.pageSize}
                                    count={data.totalItems}
                                    page={data.currentPage}
                                    disabled={loading}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </Paper>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionsAlerts 