import React, { useState } from 'react'
import { Box, Paper, TextField, Button } from '@mui/material'
import APIInvoke from '../../utils/APIInvoke'
import { toast } from 'react-hot-toast';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { setLoading } from '../../template/app-actions';


const Restore = (props) => {

    const [state, setState] = useState({
        email: ""
    })


    const restore = async (e) => {
        try {
            e.preventDefault()
            const request = {
                email: state.email
            }

            const response = await (await APIInvoke.invokePOST(`/users/restore`, request)).json()
            setState({
                ...state,
                message: response.message
            })
        } catch (error) {
            console.error(error)
            toast.error("Unexpected error, please try again")
        }

    }

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="pt-20">
            <div className="max-w-96 m-auto">
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }} className="p-4">
                        <form onSubmit={restore}>
                            <div className="grid gap-4">
                                <TextField
                                    required
                                    size="medium"
                                    label="Type your email"
                                    name="email"
                                    onChange={onChange}
                                    value={state.email}
                                />
                                <Button variant="contained" type="submit" className="text-dark-500" >RESTORE</Button>
                            </div>
                        </form>
                    </Paper>
                </Box>
                <div>
                    <Link className="block text-center" to={`/login`}>Return to login page</Link>
                </div>
                {state.message && <Alert severity="success">{state.message}</Alert>}
            </div>
        </div>
    )
}

export default Restore

