import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../state/AppContext'
import { Link, redirect, useNavigate } from 'react-router-dom'
import { Box, Paper } from '@mui/material'
import EditProject from '../edit-project/EditProject'
import { hasText } from '../../utils/StringUtils'


const Home = (props) => {

    const [loading, setLoading] = useState(false)
    const { appContext, setAppContext } = useContext(AppContext)
    const navigate = useNavigate()
    const defaultProject = appContext.project
    const projects = appContext.projects

    if(projects.length === 0 ) {
        console.log("route to new project")
        window.location = `/projects/_new/edit`
        return null
    } else if (hasText(defaultProject)) {
        console.log("route to defaultProject", defaultProject)
        window.location = `/projects/${defaultProject}/collections`
        return null
    } else {
        console.log("route to home")
        return (
            <div >
                <div className="content">
                    <div className="grid gap-8">
                        <p>Select a project</p>
                        <div className="grid grid-cols-4 gap-8">
                            {appContext.projects.map(project => (
                                <Link key={project.id} to={`/projects/${project.name}`} className="no-underline">
                                    <Box>
                                        <Paper>
                                            <p className="no-underline p-4">{project.name}</p>
                                        </Paper>
                                    </Box>
                                </Link>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default Home