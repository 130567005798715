import store from '../redux/store'
import {
    APP_SET_LOADING
} from '../redux/redux-const'


const getState = () => {
    return store.getState().pages.app
}

export const setLoading = (isLoading) => {
    const state = getState()
    store.dispatch({
        ...state,
        type: APP_SET_LOADING,
        payload: {
            loading: isLoading
        }
    })
}
